import React, { Component } from "react";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error("ErrorBoundary caught an error", error, errorInfo);
		this.setState({ error: error, errorInfo: errorInfo });
	}

	render() {
		if (this.state.hasError) {
			// Log the error but continue rendering the children
			console.error(
				"ErrorBoundary caught an error",
				this.state.error,
				this.state.errorInfo
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
