import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useVote = (post, updatePost) => {
	if (!post) {
		throw new Error("Post is required for useVote hook");
	}

	const [upvotes, setUpvotes] = useState(post.upvotes);
	const [downvotes, setDownvotes] = useState(post.downvotes);
	const { isLoggedIn, setIsLoggedIn, user, setUser } = useContext(AuthContext);

	// useEffect(() => {
	// 	// This effect will run whenever the user context changes
	// 	if (user) {
	// 		// Perform any actions needed when the user updates
	// 		console.log("User updated:", user);
	// 	}
	// }, [user]);

	const handleVote = (post, type) => async () => {
		try {
			const voteData = {
				userId: user.id,
				postId: post.postId,
				voteType: type,
			};
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/votes/votePost`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(voteData),
				}
			);

			if (response.ok) {
				const data = await response.json();
				setUpvotes(data.upvotes);
				setDownvotes(data.downvotes);
				updatePost(post.postId, data.upvotes, data.downvotes);
			} else {
				console.error("Failed to vote");
			}
		} catch (error) {
			console.error("Error voting:", error);
		}
	};

	return { handleVote, upvotes, downvotes };
};

export default useVote;
