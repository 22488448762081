import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
} from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import PostMain from "../components/PostMain";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import PopupDialog from "../components/PopupDialog";
import { useTheme } from "../components/ThemeContext";
import BottomNavBar from "../components/BottomNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContext } from "../context/PageContext";
import { throttle } from "lodash";
import Masonry from "react-masonry-css";
import MasonryStyles from "../css/MasonryStyles.css";

const MemoizedLeftSidebar = React.memo(LeftSidebar);
const MemoizedRightSidebar = React.memo(RightSidebar);

function MainContent() {
	const { theme } = useTheme();
	const [width, setWidth] = useState(window.innerWidth);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const tabIndex = parseInt(queryParams.get("tab")) ?? 1;
	const [bottomNavValue, setBottomNavValue] = useState(tabIndex);
	const [openDialog, setOpenDialog] = useState(true);
	const [posts, setPosts] = useState([]);
	const { currentPage } = useContext(PageContext);
	const isMobile = width <= 770;
	const navigate = useNavigate();

	// Pagination and loading states
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const loadingRef = useRef(false); // Use ref for synchronous loading state

	const handleChange = (event, newValue) => {
		setBottomNavValue(newValue);
		navigate(`/?tab=${newValue}`);
	};

	// Fetch posts function with pagination
	const fetchPostMain = useCallback(async () => {
		if (loadingRef.current || !hasMore) return;
		loadingRef.current = true;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/globalPosts`,
				{
					params: {
						sortBy: currentPage,
						page: page,
						size: 10,
					},
				}
			);
			const data = response.data;
			setPosts((prevPosts) => {
				const newPosts = data.content.filter(
					(post) => !prevPosts.some((p) => p.postId === post.postId)
				);
				return [...prevPosts, ...newPosts];
			});
			setHasMore(!data.last);
		} catch (error) {
			console.error("Error fetching posts by community:", error);
		} finally {
			loadingRef.current = false;
		}
	}, [page, currentPage, hasMore]);

	// Fetch posts when page or currentPage changes
	useEffect(() => {
		fetchPostMain();
	}, [fetchPostMain]);

	// Reset pagination when sort order changes
	useEffect(() => {
		setPage(0);
		setPosts([]);
		setHasMore(true);
	}, [currentPage]);

	// Handle window resize
	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Infinite scroll handler
	useEffect(() => {
		const handleScroll = throttle(() => {
			if (
				window.innerHeight + document.documentElement.scrollTop >=
				document.documentElement.offsetHeight * 0.9
			) {
				if (hasMore && !loadingRef.current) {
					setPage((prevPage) => prevPage + 1);
				}
			}
		}, 300);

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [hasMore]);

	// Handle bottom navigation changes
	useEffect(() => {
		let currentTab = parseInt(queryParams.get("tab"));
		if (isNaN(currentTab) && isMobile) {
			currentTab = 1;
			navigate(`/?tab=${currentTab}`, { replace: true });
		}
		setBottomNavValue(currentTab);
	}, [location.search, navigate, isMobile]);

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	// Define the breakpoint columns for responsive design
	const breakpointColumnsObj = {
		default: 3, // Default number of columns
		1100: 3,
		700: 2,
		500: 1,
	};

	const updatePostVotes = (postId, upvotes, downvotes) => {
		setPosts((prevPosts) =>
			prevPosts.map((post) =>
				post.postId === postId
					? {
							...post,
							upvotes,
							downvotes,
					  }
					: post
			)
		);
	};

	const renderMobileBottomNavContent = (bottomNavValue) => {
		switch (bottomNavValue) {
			case 0:
				return <MemoizedLeftSidebar />;
			case 1:
				return (
					<Box sx={{ padding: "1rem" }}>
						{posts && (
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className="my-masonry-grid"
								columnClassName="my-masonry-grid_column">
								{posts.map((post) => (
									<div key={post.postId}>
										<PostMain post={post} />
									</div>
								))}
							</Masonry>
						)}
					</Box>
				);
			case 2:
				return <MemoizedRightSidebar />;
			default:
				return null;
		}
	};

	return (
		<>
			<PopupDialog open={openDialog} handleClose={handleDialogClose} />
			<Box
				sx={{
					pb: isMobile ? 2 : 2,
					pt: isMobile ? 0 : 0,
					mt: 0,
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}>
				<ResponsiveTopBar isMobile={isMobile} />

				{isMobile ? (
					<Box sx={{ flexGrow: 1 }}>
						{renderMobileBottomNavContent(bottomNavValue)}
					</Box>
				) : (
					<Grid container spacing={3} columns={12}>
						<Grid item xs={2}>
							<MemoizedLeftSidebar />
						</Grid>
						<Grid item xs={8}>
							<Box sx={{ width: "100%" }}>
								{posts && (
									<Masonry
										breakpointCols={breakpointColumnsObj}
										className="my-masonry-grid"
										columnClassName="my-masonry-grid_column">
										{posts.map((post) => (
											<div key={post.postId}>
												<PostMain post={post} />
											</div>
										))}
									</Masonry>
								)}
							</Box>
						</Grid>
						<Grid item xs={2}>
							<MemoizedRightSidebar />
						</Grid>
					</Grid>
				)}
				{isMobile && (
					<BottomNavBar
						bottomNavValue={bottomNavValue}
						onNavChange={handleChange}
					/>
				)}
			</Box>
		</>
	);
}

export default MainContent;
