// CommunityCard.js
import React from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { ThemeProvider } from "@mui/system";
import { useTheme } from "./ThemeContext";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

const CommunityCard = ({
	name,
	icon,
	letter_logo,
	src_img,
	arrow,
	percentage,
	link,
}) => {
	const { theme } = useTheme();
	const styles = {
		avatarSpan: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginRight: "12px",
			width: "32px",
			height: "32px",
			borderRadius: "50%",
		},
		sectionCard: {
			display: "flex",
			alignItems: "center",
			marginBottom: "12px",
			background: theme.palette.background.default,
			borderRadius: "16px",
			// padding: '8px 16px',
			transition: "background-color 0.3s",
			textDecoration: "none",
			hover: {
				background: "#1d3146",
			},
			icon: {
				fontSize: "22px",
				marginRight: "12px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "32px",
				height: "32px",
				borderRadius: "50%",
				color: "#fff",
				textAlign: "center",
			},
			letterLogo: {
				fontSize: "16px",
				marginRight: "12px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "32px",
				height: "32px",
				borderRadius: "50%",
				color: "#fff",
				textAlign: "center",
			},
			name: {
				flexGrow: 1,
				color: theme.palette.text.green,
				fontWeight: 500,
				textDecoration: "none",
			},
			arrowUp: {
				color: "#17c368",
				cursor: "pointer",
			},
			arrowDown: {
				color: "#b62525",
				cursor: "pointer",
			},
			percentageUp: {
				color: "#17c368",
				cursor: "pointer",
			},
			percentageDown: {
				color: "#b62525",
				cursor: "pointer",
			},
		},
	};

	return (
		<ThemeProvider theme={theme}>
			<Link to={`/${link || name}`} style={{ textDecoration: "none" }}>
				<div style={styles.sectionCard}>
					{icon && <span style={styles.sectionCard.icon}>{icon}</span>}
					{letter_logo && (
						<span style={styles.sectionCard.letterLogo}>{letter_logo}</span>
					)}
					{src_img && (
						<span style={styles.avatarSpan}>
							{" "}
							<Avatar
								alt="stock_logo"
								src={require("" + src_img)}
								sx={{
									width: "32px",
									height: "32px",
									marginRight: "12px",
									borderRadius: "50%",
									backgroundColor: "#FFFF",
								}}
							/>
						</span>
					)}
					<span style={styles.sectionCard.name}>{name}</span>
					{arrow == "UP" && <FaArrowUp style={styles.sectionCard.arrowUp} />}
					{arrow == "DOWN" && (
						<FaArrowDown style={styles.sectionCard.arrowDown} />
					)}
					{percentage && percentage[0] == "+" && (
						<span style={styles.sectionCard.percentageUp}>{percentage}</span>
					)}
					{percentage && percentage[0] == "-" && (
						<span style={styles.sectionCard.percentageDown}>{percentage}</span>
					)}
				</div>
			</Link>
		</ThemeProvider>
	);
};

export default CommunityCard;
