import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "./ThemeContext";
import Grid from "@mui/material/Grid";

const CommentBox = ({ comment, setComment, handleSubmit, handleCancel }) => {
	const { theme } = useTheme();
	const [isFocused, setIsFocused] = useState(false);

	return (
		<Box
			sx={{
				border: `${isFocused ? "2px" : "1px"} solid ${
					isFocused ? theme.palette.primary.main : theme.palette.primary.main
				}`,
				padding: "0.1rem",
				borderRadius: "8px",
				marginTop: "1rem",
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.secondary.main,
			}}>
			<TextField
				fullWidth
				multiline
				rows={2}
				variant="outlined"
				value={comment}
				onChange={(e) => setComment(e.target.value)}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				sx={{
					margin: "0rem",
					color: theme.palette.secondary.main,
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							border: "none",
						},
					},
					"& .MuiInputBase-input": {
						color: theme.palette.secondary.main,
						backgroundColor: `${theme.palette.background.paper} !important`,
					},
				}}
			/>
			<Grid
				container
				justifyContent="flex-end"
				spacing={0}
				sx={{ marginTop: "0rem" }}>
				<Grid item>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						size="small"
						sx={{
							marginRight: "1rem",
							marginBottom: ".5rem",
							color: theme.palette.text.dark,
							backgroundColor: theme.palette.background.cancel,
							borderColor: theme.palette.background.cancel,
							"&:hover": {
								backgroundColor: theme.palette.background.cancelHighlight,
								borderColor: theme.palette.background.cancelHighlight,
								color: theme.palette.text.primary,
							},
						}}>
						Cancel
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						size="small"
						sx={{
							marginRight: ".5rem",
							marginBottom: ".5rem",
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.text.dark,
							"&:hover": {
								backgroundColor: theme.palette.background.darkGreen,
								color: theme.palette.text.primary,
							},
						}}>
						Comment
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CommentBox;
