import React, { useEffect, useRef, useState, memo } from "react";
import { useTheme } from "./ThemeContext";

function AdvancedChart2({ stockTicker, isMobile }) {
	const container = useRef();
	const [width, setWidth] = useState(window.innerWidth);
	const { theme } = useTheme();

	const getWidgetDimensions = () => {
		return {
			width: width < 600 ? width.toString() : "100%",
			height: 450,
		};
	};

	const stockTickerName = stockTicker.replace("-", ":");

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		console.log("StockHeatMap rendered");

		const script = document.createElement("script");
		script.src =
			"https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
		script.type = "text/javascript";
		script.async = true;
		const { width, height } = getWidgetDimensions();
		const url =
			process.env.REACT_APP_SC_BASE_URL + "/s/{tvexchange}%3A{tvsymbol}";
		script.innerHTML = `
         {
        "width": "100%",
        "height": ${isMobile ? 400 : 450},
        "symbol": "${stockTickerName}",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "2",
        "locale": "en",
        "enable_publishing": false,
        "withdateranges": true,
        "range": "1D",
        "hide_side_toolbar": false,
        "allow_symbol_change": true,
        "calendar": false,
        "support_host": "https://www.tradingview.com",
        "backgroundColor": "${theme.palette.background.default}",
        "show_popup_button": true
        }`;

		const appendScript = () => {
			if (container.current) {
				container.current.appendChild(script);
			}
		};

		if (document.readyState === "complete") {
			appendScript();
		} else {
			window.addEventListener("load", appendScript);
		}

		return () => {
			if (container.current) {
				container.current.innerHTML = "";
			}
			window.removeEventListener("load", appendScript);
		};
	}, [width]);

	return (
		<div
			className="tradingview-widget-container"
			ref={container}
			style={{ zIndex: 1 }} // Ensure proper z-index
		></div>
	);
}

export default memo(AdvancedChart2);
