import { useState, useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useCommentVote = (comment, updateComments) => {
	const [upvotes, setUpvotes] = useState(comment.upvotes);
	const [downvotes, setDownvotes] = useState(comment.downvotes);
	const { user } = useContext(AuthContext);
	const [isVoting, setIsVoting] = useState(false);

	const handleVote = useCallback(
		async (comment, type) => {
			if (!user) {
				console.error("User is not logged in");
				return;
			}

			if (isVoting) return; // Prevent multiple requests
			setIsVoting(true);

			try {
				const response = await fetch(
					`${process.env.REACT_APP_SC_API_BASE_URL}/api/votes/voteComment`,
					{
						method: "POST",
						headers: {
							accept: "*/*",
							Authorization: `Bearer ${user.token}`,
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							userId: user.id,
							commentId: comment.commentId,
							voteType: type,
						}),
					}
				);

				if (response.ok) {
					const data = await response.json();
					setUpvotes(data.upvotes);
					setDownvotes(data.downvotes);

					// Update the comments state
					updateComments((prevComments) =>
						prevComments.map((c) =>
							c.commentId === comment.commentId
								? { ...c, upvotes: data.upvotes, downvotes: data.downvotes }
								: c
						)
					);
				} else {
					console.error("Failed to vote on comment");
				}
			} catch (error) {
				console.error("Error voting on comment:", error);
			} finally {
				setIsVoting(false);
			}
		},
		[comment.commentId, updateComments, isVoting, user]
	);

	return { handleVote, upvotes, downvotes };
};

export default useCommentVote;
