import React, { useState, useEffect, Suspense, memo } from "react";
import { Grid } from "@mui/material";
import "./RightSidebar.css";
import { StockMarket, MarketOverview } from "react-tradingview-embed";
import StockHeatMap from "./StockHeatMap";
import { useTheme } from "./ThemeContext";
import ErrorBoundary from "../utils/ErrorBoundary";

const stockData = [
	{ time: "9 AM", price: 230 },
	{ time: "10 AM", price: 235 },
	{ time: "11 AM", price: 240 },
];

function RightSidebar() {
	const [width, setWidth] = useState(window.innerWidth);
	const { theme } = useTheme();

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
			style={{ marginTop: "0rem" }}>
			<Grid item>
				<div
					style={{
						marginRight: "1rem",
						marginTop: 0,
						backgroundColor: theme.palette.background.default,
						borderRadius: "2%",
						zIndex: 2, // Ensure proper z-index
					}}>
					<StockHeatMap />
				</div>
			</Grid>

			<Grid item>
				<div
					style={{
						marginRight: "1rem",
						marginTop: 0,
						backgroundColor: theme.palette.background.default,
						borderRadius: "2%",
						zIndex: 1, // Ensure proper z-index
					}}>
					<ErrorBoundary>
						<MarketOverview
							widgetProps={{
								colorTheme: "dark",
								dateRange: "3M",
								showChart: true,
								locale: "en",
								width: width < 600 ? width.toString() : "100%",
								height: 450,
								largeChartUrl: `${process.env.REACT_APP_SC_BASE_URL}/s/{tvexchange}-{tvsymbol}`,
								showSymbolLogo: true,
								showFloatingTooltip: true,
								plotLineColorGrowing: "rgba(0, 255, 0, 1)",
								plotLineColorFalling: "rgba(255, 0, 0, 1)",
								gridLineColor: "rgba(240, 243, 250, 0)",
								scaleFontColor: "rgba(106, 109, 120, 1)",
								belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
								belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
								belowLineFillColorGrowingBottom: "rgba(147, 196, 125, 0)",
								belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
								symbolActiveColor: "rgba(41, 98, 255, 0.12)",
								isTransparent: true,
								tabs: [
									{
										title: "Indices",
										symbols: [
											{
												s: "FOREXCOM:SPXUSD",
												d: "S&P 500 Index",
											},
											{
												s: "NASDAQ:NDX",
												d: "NASDAQ 100",
											},
											{
												s: "FOREXCOM:DJI",
												d: "Dow Jones Industrial Average Index",
											},
											{
												s: "INDEX:NKY",
												d: "Nikkei 225",
											},
											{
												s: "INDEX:DEU40",
												d: "DAX Index",
											},
											{
												s: "FOREXCOM:UKXGBP",
												d: "FTSE 100 Index",
											},
											{
												s: "NASDAQ:NDX",
											},
										],
										originalTitle: "Indices",
									},
									{
										title: "Futures",
										symbols: [
											{
												s: "CME_MINI:ES1!",
												d: "S&P 500",
											},
											{
												s: "CME:6E1!",
												d: "Euro",
											},
											{
												s: "COMEX:GC1!",
												d: "Gold",
											},
											{
												s: "NYMEX:CL1!",
												d: "WTI Crude Oil",
											},
											{
												s: "NYMEX:NG1!",
												d: "Gas",
											},
											{
												s: "CBOT:ZC1!",
												d: "Corn",
											},
										],
										originalTitle: "Futures",
									},
									{
										title: "Bonds",
										symbols: [
											{
												s: "CBOT:ZB1!",
												d: "T-Bond",
											},
											{
												s: "CBOT:UB1!",
												d: "Ultra T-Bond",
											},
											{
												s: "EUREX:FGBL1!",
												d: "Euro Bund",
											},
											{
												s: "EUREX:FBTP1!",
												d: "Euro BTP",
											},
											{
												s: "EUREX:FGBM1!",
												d: "Euro BOBL",
											},
										],
										originalTitle: "Bonds",
									},
									{
										title: "Forex",
										symbols: [
											{
												s: "FX:EURUSD",
												d: "EUR to USD",
											},
											{
												s: "FX:GBPUSD",
												d: "GBP to USD",
											},
											{
												s: "FX:USDJPY",
												d: "USD to JPY",
											},
											{
												s: "FX:USDCHF",
												d: "USD to CHF",
											},
											{
												s: "FX:AUDUSD",
												d: "AUD to USD",
											},
											{
												s: "FX:USDCAD",
												d: "USD to CAD",
											},
										],
										originalTitle: "Forex",
									},
								],
							}}
						/>
					</ErrorBoundary>
				</div>
			</Grid>

			<Grid item>
				<div
					style={{
						marginTop: "1rem",
						marginRight: "1rem",
						backgroundColor: theme.palette.background.default,
						borderRadius: "2%",
						zIndex: 1, // Ensure proper z-index
					}}>
					<Suspense fallback={<div>Loading Market Overview...</div>}>
						<ErrorBoundary>
							<StockMarket
								widgetProps={{
									colorTheme: "dark",
									dateRange: "12M",
									exchange: "US",
									showChart: true,
									locale: "en",
									isTransparent: true,
									showSymbolLogo: false,
									showFloatingTooltip: false,
									width: width < 600 ? width.toString() : "100%",
									height: 600,
									plotLineColorGrowing: "rgba(41, 255, 209, 1)",
									plotLineColorFalling: "rgba(255, 41, 41, 1)",
									gridLineColor: "rgba(240, 243, 250, 0)",
									scaleFontColor: "rgba(106, 109, 120, 1)",
									belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
									belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
									belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
									belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
									symbolActiveColor: "rgba(41, 98, 255, 0.12)",
									largeChartUrl:
										"www.stonkscentral.com/s/{tvexchange}-{tvsymbol}",
								}}
							/>
						</ErrorBoundary>
					</Suspense>
				</div>
			</Grid>
		</Grid>
	);
}

export default memo(RightSidebar);
