export const themeOptions = {
	palette: {
		mode: "dark",
		primary: {
			main: "#62F481",
		},
		secondary: {
			main: "#f9f9cf",
		},
		text: {
			secondary: "#f9f9cf",
			disabled: "#FF4500",
		},
		background: {
			paper: "#0C192B",
			default: "#060D1D",
		},
		success: {
			main: "#62F481",
		},
		error: {
			main: "#FF4500",
		},
	},
};
