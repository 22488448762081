// components/LeftSidebar.js
import React from "react";
import { styled } from "@mui/material/styles";
import CommunityCard from "./CommunityCard";
import "./HotCommunities.css"; // Make sure to create a corresponding CSS file
import "./RightSidebar.css"; // Make sure to create a corresponding CSS file
import { ThemeProvider } from "@mui/system";
import { useTheme } from "./ThemeContext";

import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

const SidebarContainer = styled("div")(({ theme }) => ({
	width: "auto",
	backgroundColor: "rgba(255, 255=, 255, 0.1)", // Your desired background color for the sidebar
	color: "white",
	borderRadius: theme.shape.borderRadius,
	overflow: "hidden",
	marginLeft: "15px",
}));

const CardContainer = styled("div")({
	marginTop: "10px", // Adjust the margin value as per your requirement
});

const stocks = [
	{ id: "h", name: "s/GME", src_img: "./posts_images/gme_logo.png" },
	{ id: "c", name: "s/TSLA", src_img: "./posts_images/tsla_logo.webp" },
	{ id: "p", name: "s/AAPL", src_img: "./posts_images/appl_logo.jpeg" },
];

const communities = [
	{ id: "h", name: "c/ThetaGang", icon: "🔥" },
	{ id: "c", name: "c/InvestingGame", icon: "📈" },
	{ id: "p", name: "c/PersonalFinance", icon: "💰" },
];
// Mock data for top analysts
const topAnalysts = [
	{ name: "u/InvestingKitten", letter_logo: "IK" },
	{ name: "u/JimCraimer", letter_logo: "JC" },
	{ name: "u/futuresTrader89", letter_logo: "FT" },
	// ...more analysts
];

// components/LeftSidebar.js
// ... (other imports)

const LeftSidebar = () => {
	const { theme } = useTheme();
	return (
		<ThemeProvider theme={theme}>
			<SidebarContainer>
				<Grid container spacing={0} direction="column" alignItems="left">
					<Grid item xs={6}>
						<div
							style={{
								backgroundColor: theme.palette.background.default,
								marginTop: 15,
							}}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: ".5rem",
									}}>
									Trending Tickers
								</Box>

								{stocks.map((community) => (
									<CommunityCard
										key={community.id}
										name={community.name}
										src_img={community.src_img}
									/>
								))}
							</Box>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										marginTop: 0,
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: ".5rem",
									}}>
									Trending Communities
								</Box>
								{communities.map((community) => (
									<CommunityCard
										key={community.id}
										name={community.name}
										icon={community.icon}
									/>
								))}
							</Box>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: "0.5rem",
									}}>
									Trending Users
								</Box>

								{topAnalysts.map((analyst) => (
									<CommunityCard
										key={analyst.name}
										name={analyst.name}
										letter_logo={analyst.letter_logo}
									/>
								))}
							</Box>
						</div>
					</Grid>
				</Grid>
			</SidebarContainer>
		</ThemeProvider>
	);
};

export default LeftSidebar;
