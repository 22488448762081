import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
	marginBottom: theme.spacing(2),
	backgroundColor: "rgba(255, 255, 255, 0.1)",
	color: theme.palette.common.white,
	marginTop: "10px",
}));

export default StyledCard;
