import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
	Box,
	TextField,
	Button,
	Grid,
	Chip,
	Typography,
	Paper,
	Autocomplete,
	CircularProgress,
	Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import BottomNavBar from "../components/BottomNavBar";
import { useTheme } from "../components/ThemeContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import debounce from "lodash/debounce";

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: "1.5rem",
	maxWidth: 800,
	margin: "0 auto",
	backgroundColor: theme.palette.background.default,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	marginBottom: 16,
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&:hover fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.primary.main,
		},
	},
	"& .MuiInputBase-input": {
		color: theme.palette.secondary.main,
		backgroundColor: `${theme.palette.background.default} !important`,
	},
	"& input:-webkit-autofill": {
		WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
		WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
	},
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.text.dark,
	marginTop: "2rem",
	"&:hover": {
		backgroundColor: theme.palette.background.darkGreen,
		color: theme.palette.text.primary,
	},
}));

const StyledChip = styled(Chip)(({ theme }) => ({
	marginRight: "1rem",
	marginTop: "2rem",
	color: theme.palette.text.dark,
	backgroundColor: theme.palette.primary.main,
}));

const StyledImagePreview = styled("img")({
	width: "100px",
	height: "100px",
	objectFit: "cover",
	marginRight: 8,
});

const CommunitySearch = ({ setCommunityName, classes }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const [selectedCommunity, setSelectedCommunity] = useState(null);
	const { theme } = useTheme();

	useEffect(() => {
		if (query.length > 0) {
			const fetchData = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${query}`
					);
					setResults(response.data);
				} catch (err) {
					console.error("Error fetching data:", err);
				}
			};
			fetchData();
		} else {
			setResults([]);
		}
	}, [query]);

	const handleSelect = (event, value) => {
		setSelectedCommunity(value);
		if (value) {
			setCommunityName(value.name);
		}
	};

	return (
		<div>
			<Autocomplete
				options={results}
				getOptionLabel={(option) => option.name}
				onInputChange={(event, newInputValue) => setQuery(newInputValue)}
				onChange={handleSelect}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						label="Search for communities..."
						variant="outlined"
						fullWidth
						InputLabelProps={{
							style: { color: theme.palette.secondary.main },
						}}
						sx={{
							marginTop: "1.5rem",
							color: theme.palette.secondary.main,
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&:hover fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.Mui-focused fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.MuiAutocomplete-root": {
									backgroundColor: `${theme.palette.background.default} !important`,
								},
							},
							"& .MuiInputBase-input": {
								color: theme.palette.secondary.main,
								backgroundColor: `${theme.palette.background.default} !important`,
							},
							"& input:-webkit-autofill": {
								WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
								WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
							},
						}}
					/>
				)}
			/>
		</div>
	);
};

const TagSearch = ({ tags, setTags, classes }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const { theme } = useTheme();

	useEffect(() => {
		if (query.length > 0) {
			const fetchData = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${query}`
					);
					setResults(response.data);
				} catch (err) {
					console.error("Error fetching data:", err);
				}
			};
			fetchData();
		} else {
			setResults([]);
		}
	}, [query]);

	const handleSelect = (event, value) => {
		if (value && !tags.includes(value.name) && tags.length < 3) {
			setTags([...tags, value.name]);
			setQuery(""); // Clear the text input
		}
	};

	const handleCreateTag = (event) => {
		if (
			event.key === "Enter" &&
			query &&
			!tags.includes(query) &&
			tags.length < 3
		) {
			setTags([...tags, query]);
			setQuery(""); // Clear the text input
		}
	};

	return (
		<div>
			<Box sx={{ mb: 2 }}>
				{tags.map((tag, index) => (
					<Chip
						key={index}
						label={tag}
						onDelete={() => setTags(tags.filter((t) => t !== tag))}
						sx={{
							mr: "1rem",
							mt: "2rem",
							color: theme.palette.text.dark,
							backgroundColor: theme.palette.primary.main,
						}}
					/>
				))}
			</Box>
			<Autocomplete
				options={results}
				getOptionLabel={(option) => option.name}
				onInputChange={(event, newInputValue) => setQuery(newInputValue)}
				onChange={handleSelect}
				inputValue={query}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						label="Search or create tags..."
						variant="outlined"
						fullWidth
						onKeyDown={handleCreateTag}
						InputLabelProps={{
							style: { color: theme.palette.secondary.main },
						}}
						sx={{
							marginTop: "1rem",
							color: theme.palette.secondary.main,
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&:hover fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.Mui-focused fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.MuiAutocomplete-root": {
									backgroundColor: `${theme.palette.background.default} !important`,
								},
							},
							"& .MuiInputBase-input": {
								color: theme.palette.secondary.main,
								backgroundColor: `${theme.palette.background.default} !important`,
							},
							"& input:-webkit-autofill": {
								WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
								WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
							},
						}}
					/>
				)}
			/>
		</div>
	);
};

const CommunityNameInput = ({ setCommunityName, classes }) => {
	const [query, setQuery] = useState("");
	const [isAvailable, setIsAvailable] = useState(null);
	const { theme } = useTheme();

	const checkAvailability = debounce(async (name) => {
		if (name.length > 0) {
			// Replace with your API call to check community name availability
			const response = await axios.get(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${name}`
			);
			const data = response.data;
			const isQueryInData = data.some((item) => item.name === name);
			setIsAvailable(!isQueryInData);
		} else {
			setIsAvailable(null);
		}
	}, 3000);

	useEffect(() => {
		checkAvailability(query);
	}, [query]);

	return (
		<div>
			<StyledTextField
				label="Community Name"
				variant="outlined"
				fullWidth
				value={query}
				onChange={(e) => {
					setQuery(e.target.value);
					setCommunityName(e.target.value);
				}}
				InputLabelProps={{
					style: { color: theme.palette.secondary.main },
				}}
				sx={{
					marginTop: "1.5rem",
					color: theme.palette.secondary.main,
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: theme.palette.primary.main,
						},
						"&:hover fieldset": {
							borderColor: theme.palette.primary.main,
						},
						"&.Mui-focused fieldset": {
							borderColor: theme.palette.primary.main,
						},
					},
					"& .MuiInputBase-input": {
						color: theme.palette.secondary.main,
						backgroundColor: `${theme.palette.background.default} !important`,
					},
					"& input:-webkit-autofill": {
						WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
						WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
					},
				}}
			/>
			{isAvailable !== null && (
				<Alert
					severity={isAvailable ? "success" : "error"}
					sx={{
						backgroundColor: theme.palette.background.default,
						color: isAvailable
							? theme.palette.primary.main
							: theme.palette.error.main,
					}}>
					{isAvailable
						? "Community name is available"
						: "Community name is already taken"}
				</Alert>
			)}
		</div>
	);
};
function CreateCommunity() {
	const { theme } = useTheme();
	const [width, setWidth] = useState(window.innerWidth);
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const [communityName, setCommunityName] = useState("");
	const [tags, setTags] = useState([]);
	const [communityDescription, setCommunityDescription] = useState("");
	const [communityRules, setCommunityRules] = useState("");
	const [icon, setIcon] = useState("");
	const [banner, setBanner] = useState("");
	const [uploading, setUploading] = useState(false);
	const [iconErrorMessage, setIconErrorMessage] = useState("");
	const [bannerErrorMessage, setBannerErrorMessage] = useState("");
	const isMobile = width <= 770;
	const navigate = useNavigate();
	const { isLoggedIn, setIsLoggedIn, user, setUser } = useContext(AuthContext);

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/signin"); // Redirect to sign-in page if not logged in
		}
	}, [isLoggedIn, navigate]);

	// useEffect(() => {
	// 	const handleResize = () => setWidth(window.innerWidth);
	// 	window.addEventListener("resize", handleResize);
	// 	return () => window.removeEventListener("resize", handleResize);
	// }, []);

	const handleBottomNavChange = (event, newValue) => {
		setBottomNavValue(newValue);
		navigate(`/?tab=${newValue}`);
	};

	const handleIconUpload = (event) => {
		const iconFile = event.target.files[0];
		if (iconFile) {
			setIcon(iconFile);
		}
	};

	const handleDeleteIcon = () => {
		setIcon(null);
	};

	const handleBannerUpload = (event) => {
		const bannerFile = event.target.files[0];
		if (bannerFile) {
			setBanner(bannerFile);
		}
	};

	const handleDeleteBanner = () => {
		setBanner(null);
	};

	const uploadFile = async (file, user) => {
		if (!file) return null;

		const formData = new FormData();
		formData.append("file", file);
		formData.append("userName", user.username);

		const requestData = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
			body: formData,
		};

		const response = await fetch(
			`${process.env.REACT_APP_SC_API_BASE_URL}/api/files/upload`,
			requestData
		);

		const responseText = await response.text();
		console.log("Response Text:", responseText);

		const fileUrl = responseText.split("/uploads/")[1];
		return fileUrl;
	};

	const handleSubmit = async () => {
		setUploading(true);

		try {
			const iconUrl = await uploadFile(icon, user);
			const bannerUrl = await uploadFile(banner, user);

			const communityData = {
				userName: user.username,
				name: communityName,
				description: communityDescription,
				rules: communityRules,
				icon: iconUrl,
				banner: bannerUrl,
			};

			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/createCommunity`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(communityData),
				}
			);

			if (response.ok) {
				const data = await response.json();
				console.log("Community created:", data);
				const communityName = data.communityName;
				navigate(`/community/${communityName}`);
			} else {
				alert("Failed to create community");
			}
		} catch (error) {
			console.error("Error creating community:", error);
			alert("Failed to create community");
		} finally {
			setUploading(false);
		}
	};

	return (
		<>
			<Box
				sx={{
					pb: isMobile ? 2 : 2,
					pt: isMobile ? 0 : 0,
					mt: 0,
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}>
				<ResponsiveTopBar isMobile={isMobile} />
				<Grid container spacing={2} justifyContent="center">
					<Grid item xs={12} sm={8} justifyContent="center">
						<StyledPaper theme={theme} elevation={3}>
							<Typography
								variant="h4"
								gutterBottom
								sx={{
									color: theme.palette.text.primary,
								}}>
								Create a New Community
							</Typography>
							<CommunityNameInput
								setCommunityName={setCommunityName}
								theme={theme}
							/>
							<StyledTextField
								label="Community Description"
								variant="outlined"
								fullWidth
								multiline
								rows={4}
								value={communityDescription}
								InputLabelProps={{
									style: { color: theme.palette.secondary.main },
								}}
								onChange={(e) => setCommunityDescription(e.target.value)}
								sx={{
									marginTop: "2rem",
								}}
								theme={theme}
							/>
							<StyledTextField
								label="Community Rules"
								variant="outlined"
								fullWidth
								multiline
								rows={4}
								value={communityRules}
								InputLabelProps={{
									style: { color: theme.palette.secondary.main },
								}}
								onChange={(e) => setCommunityRules(e.target.value)}
								sx={{
									marginTop: "2rem",
								}}
								theme={theme}
							/>
							<StyledButton variant="contained" component="label" theme={theme}>
								Upload Icon
								<input
									type="file"
									hidden
									multiple
									accept="image/*"
									onChange={handleIconUpload}
								/>
							</StyledButton>
							{iconErrorMessage && (
								<Alert severity="error">{iconErrorMessage}</Alert>
							)}
							<Box sx={{ display: "flex", mt: "2rem" }}>
								{icon && (
									<StyledImagePreview
										key="icon"
										src={URL.createObjectURL(icon)}
										alt="Icon Preview"
									/>
								)}
							</Box>

							<StyledButton variant="contained" component="label" theme={theme}>
								Upload Banner
								<input
									type="file"
									hidden
									multiple
									accept="image/*"
									onChange={handleBannerUpload}
								/>
							</StyledButton>
							{bannerErrorMessage && (
								<Alert severity="error">{bannerErrorMessage}</Alert>
							)}
							<Box sx={{ display: "flex", mt: "2rem" }}>
								{banner && (
									<div>
										<StyledImagePreview
											key="banner"
											src={URL.createObjectURL(banner)}
											alt="Banner Preview"
										/>
									</div>
								)}
							</Box>
							<StyledButton
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={uploading}
								theme={theme}>
								{uploading ? (
									<CircularProgress size={24} />
								) : (
									"Create Community"
								)}
							</StyledButton>
						</StyledPaper>
					</Grid>
				</Grid>
				{isMobile && (
					<BottomNavBar
						bottomNavValue={bottomNavValue}
						onNavChange={handleBottomNavChange}
					/>
				)}
			</Box>
		</>
	);
}

export default CreateCommunity;
