import React, { useEffect, useRef } from 'react';

const TopStocksWidget = () => {
  const ref = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
    script.async = true;
    script.type = "text/javascript";
    const scriptContent = document.createTextNode(JSON.stringify({
      container_id: "tradingview-widget-container",
      colorTheme: "dark",
      dateRange: "1D",
      exchange: "US",
      showChart: true,
      locale: "en",
      width: 300,
      height: 550,
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: false,
      showFloatingTooltip: true,
      plotLineColorGrowing: "rgba(0, 255, 0, 0.89)",
      plotLineColorFalling: "rgba(255, 82, 26, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(106, 109, 120, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)"
    }));
    script.appendChild(scriptContent);
    ref.current.appendChild(script);

    return () => {
      ref.current.removeChild(script);
    };
  }, []);

  return (
    <div id="tradingview-widget-container" ref={ref}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TopStocksWidget;
