export const PositionsSummary = [
	{
		id: 1,
		position: "Long",
		stock: "TSLA",
		title: "YOLO $TSLA Calls for Earnings Beat - Who's With Me?",
		postDate: "2024-04-15",
		priceAtPost: 720.5,
		currentPrice: 730.2,
		Gain: "+1.35%",
	},
	{
		id: 2,
		position: "Long",
		stock: "AMD",
		title: "DD on $AMD - Ready for the Chip Surge",
		postDate: "2024-04-14",
		priceAtPost: 88.9,
		currentPrice: 92.4,
		Gain: "+3.94%",
	},
	{
		id: 3,
		position: "Short",
		stock: "NFLX",
		title: "Bear Gang Rise Up! Short $NFLX as Streaming Wars Heat Up",
		postDate: "2024-04-13",
		priceAtPost: 510.25,
		currentPrice: 495.5,
		Gain: "-2.89%",
	},
	{
		id: 4,
		position: "Long",
		stock: "AAPL",
		title: "Technical Analysis: $AAPL's Golden Cross Signal",
		postDate: "2024-04-12",
		priceAtPost: 135.75,
		currentPrice: 140.3,
		Gain: "+3.35%",
	},
	{
		id: 5,
		position: "Long",
		stock: "SPCE",
		title: "Caught a Falling Knife with $SPCE, Pray for Me",
		postDate: "2024-04-11",
		priceAtPost: 24.8,
		currentPrice: 22.65,
		Gain: "-8.67%",
	},
	{
		id: 6,
		position: "Long",
		stock: "GME",
		title: "Gains Porn from My $GME Calls!",
		postDate: "2024-04-10",
		priceAtPost: 150.0,
		currentPrice: 180.0,
		Gain: "+20.00%",
	},
	{
		id: 7,
		position: "Long",
		stock: "AMZN",
		title: "Is $AMZN a Buy Before the Split? Here’s My Analysis",
		postDate: "2024-04-09",
		priceAtPost: 3200.0,
		currentPrice: 3248.5,
		Gain: "+1.52%",
	},
	{
		id: 8,
		position: "Short",
		stock: "PLTR",
		title: "Why $PLTR Will Either Skyrocket or Crash by Next Month",
		postDate: "2024-04-08",
		priceAtPost: 25.5,
		currentPrice: 22.0,
		Gain: "-13.73%",
	},
	{
		id: 9,
		position: "Short",
		stock: "UBER",
		title: "My Put Options Playbook for $UBER Earnings Miss",
		postDate: "2024-04-07",
		priceAtPost: 45.75,
		currentPrice: 42.1,
		Gain: "-7.98%",
	},
	{
		id: 10,
		position: "Hodl",
		stock: "BTC",
		title: "Crypto Bros: Is $BTC Back or Just Another Tease?",
		postDate: "2024-04-06",
		priceAtPost: 45000.0,
		currentPrice: 47000.0,
		Gain: "+4.44%",
	},
	// Additional entries would continue this pattern
];
