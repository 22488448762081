// components/Post.js
import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import './Post.css'; // Ensure this is correctly linked

function Post({ post }) {
  return (
    <Card >
      <CardMedia
        component="img"
        src={require(""+ post.image)}
        alt="Post"
        className="post-image"
      />
      <CardContent className="post-content">
        <Typography gutterBottom variant="h6" component="div" className="post-title">
          {post.title}
        </Typography>
        <Typography variant="body2" className="post-details">
          {post.time} ago by {post.username}
        </Typography>
        <Typography variant="body1" className="post-community">
          {post.communityName}
        </Typography>
      </CardContent>
      <Box className="post-interactions">
        <IconButton aria-label="upvote" className="vote-button upvote">
          <ArrowDropUpIcon />
        </IconButton>
        <Typography variant="body2" className="vote-count upvotes">
          {post.upvotes}
        </Typography>
        <IconButton aria-label="downvote" className="vote-button downvote">
          <ArrowDropDownIcon />
        </IconButton>
        <Typography variant="body2" className="vote-count downvotes">
          {post.downvotes}
        </Typography>
      </Box>
    </Card>
  );
}
export default Post;
