import * as React from "react";
import PropTypes from "prop-types";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	Paper,
	TablePagination,
} from "@mui/material";
import {
	PositionsSummary,
	PostionsSummary,
} from "../content/post_content/PositionsSummary";

function createData(id, name, calories, fat, carbs, protein) {
	return { id, name, calories, fat, carbs, protein };
}

const rows = [
	createData(1, "Cupcake", 305, 3.7, 67, 4.3),
	createData(2, "Donut", 452, 25.0, 51, 4.9),
	createData(3, "Eclair", 262, 16.0, 24, 6.0),
	createData(4, "Frozen yoghurt", 159, 6.0, 24, 4.0),
	createData(5, "Gingerbread", 356, 16.0, 49, 3.9),
	createData(6, "Honeycomb", 408, 3.2, 87, 6.5),
	createData(7, "Ice cream sandwich", 237, 9.0, 37, 4.3),
	createData(8, "Jelly Bean", 375, 0.0, 94, 0.0),
	createData(9, "KitKat", 518, 26.0, 65, 7.0),
	createData(10, "Lollipop", 392, 0.2, 98, 0.0),
	createData(11, "Marshmallow", 318, 0, 81, 2.0),
	createData(12, "Nougat", 360, 19.0, 9, 37.0),
	createData(13, "Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) return -1;
	if (b[orderBy] > a[orderBy]) return 1;
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		return order !== 0 ? order : a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: "Dessert (100g serving)",
	},
	{ id: "calories", numeric: true, disablePadding: false, label: "Calories" },
	{ id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
	{ id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
	{ id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, postData } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{Object.keys(postData[0]).map((headCell) => (
					<TableCell
						key={headCell}
						align={"center"}
						padding={"normal"}
						sortDirection={orderBy === headCell ? order : false}>
						<TableSortLabel
							active={orderBy === headCell}
							direction={orderBy === headCell ? order : "asc"}
							onClick={createSortHandler(headCell)}>
							{headCell}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	postData: PropTypes.object.isRequired,
};

export default function EnhancedTable() {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			stableSort(rows, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage]
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<Typography variant="h6" component="div" sx={{ p: 2 }}>
					{" "}
					Analysis{" "}
				</Typography>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size="medium">
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							postData={PositionsSummary}
						/>
						<TableBody>
							{PositionsSummary.map((row, index) => (
								<TableRow hover key={index}>
									<TableCell component="th" scope="row" padding="none">
										{row.name}
									</TableCell>
									{Object.values(row).map((cell, index) => (
										<TableCell align="center">{cell}</TableCell>
									))}
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
