import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
	Box,
	TextField,
	Button,
	Grid,
	Chip,
	Typography,
	Container,
	Paper,
	Autocomplete,
	CircularProgress,
	Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import BottomNavBar from "../components/BottomNavBar";
import { useTheme } from "../components/ThemeContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		width: "100%",
// 		position: "fixed",
// 		bottom: 0,
// 		zIndex: 1000,
// 	},
// 	actionItem: {
// 		color: theme.palette.primary.main,
// 		"& .MuiBottomNavigationAction-root": {
// 			color: theme.palette.primary.main,
// 		},
// 		"& .MuiBottomNavigationAction-label": {
// 			color: theme.palette.text.secondary,
// 		},
// 		"& .Mui-selected": {
// 			color: theme.palette.primary.main,
// 		},
// 	},
// 	formField: {
// 		marginBottom: 16, // 2 * 8px (default spacing unit)
// 	},
// 	imagePreview: {
// 		width: "100px",
// 		height: "100px",
// 		objectFit: "cover",
// 		marginRight: 8, // 1 * 8px (default spacing unit)
// 	},
// 	container: {
// 		display: "flex",
// 		justifyContent: "center",
// 		alignItems: "center",
// 		minHeight: "100vh",
// 		padding: "64px 16px",
// 		boxSizing: "border-box",
// 	},
// 	paper: {
// 		padding: "1.5rem", // 4 * 8px (default spacing unit)
// 		maxWidth: 800,
// 		// width: "100%",
// 		margin: "0 auto",
// 		backgroundColor: theme.palette.background.paper,
// 	},
// }));

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: "1.5rem",
	maxWidth: 800,
	margin: "0 auto",
	backgroundColor: theme.palette.background.paper,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	marginBottom: 16,
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&:hover fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.primary.main,
		},
	},
	"& .MuiInputBase-input": {
		color: theme.palette.secondary.main,
		backgroundColor: `${theme.palette.background.default} !important`,
	},
	"& input:-webkit-autofill": {
		WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
		WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
	},
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.text.dark,
	marginTop: "2rem",
	"&:hover": {
		backgroundColor: theme.palette.background.darkGreen,
		color: theme.palette.text.primary,
	},
}));

const StyledChip = styled(Chip)(({ theme }) => ({
	marginRight: "1rem",
	marginTop: "2rem",
	color: theme.palette.text.dark,
	backgroundColor: theme.palette.primary.main,
}));

const StyledImagePreview = styled("img")({
	width: "100px",
	height: "100px",
	objectFit: "cover",
	marginRight: 8,
});

const CommunitySearch = ({ setCommunityName, classes }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const [selectedCommunity, setSelectedCommunity] = useState(null);
	const { theme } = useTheme();

	useEffect(() => {
		if (query.length > 0) {
			const fetchData = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${query}`
					);
					setResults(response.data);
				} catch (err) {
					console.error("Error fetching data:", err);
				}
			};
			fetchData();
		} else {
			setResults([]);
		}
	}, [query]);

	const handleSelect = (event, value) => {
		setSelectedCommunity(value);
		if (value) {
			setCommunityName(value.name);
		}
	};

	return (
		<div>
			<Autocomplete
				options={results}
				getOptionLabel={(option) => option.name}
				onInputChange={(event, newInputValue) => setQuery(newInputValue)}
				onChange={handleSelect}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						label="Search for communities..."
						variant="outlined"
						fullWidth
						InputLabelProps={{
							style: { color: theme.palette.secondary.main },
						}}
						sx={{
							marginTop: "2rem",
							color: theme.palette.secondary.main,
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&:hover fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.Mui-focused fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.MuiAutocomplete-root": {
									backgroundColor: `${theme.palette.background.default} !important`,
								},
							},
							"& .MuiInputBase-input": {
								color: theme.palette.secondary.main,
								backgroundColor: `${theme.palette.background.default} !important`,
							},
							"& input:-webkit-autofill": {
								WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
								WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
							},
						}}
					/>
				)}
			/>
		</div>
	);
};

const TagSearch = ({ tags, setTags, classes }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const { theme } = useTheme();

	useEffect(() => {
		if (query.length > 0) {
			const fetchData = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${query}`
					);
					setResults(response.data);
				} catch (err) {
					console.error("Error fetching data:", err);
				}
			};
			fetchData();
		} else {
			setResults([]);
		}
	}, [query]);

	const handleSelect = (event, value) => {
		if (value && !tags.includes(value.name) && tags.length < 3) {
			setTags([...tags, value.name]);
			setQuery(""); // Clear the text input
		}
	};

	const handleCreateTag = (event) => {
		if (
			event.key === "Enter" &&
			query &&
			!tags.includes(query) &&
			tags.length < 3
		) {
			setTags([...tags, query]);
			setQuery(""); // Clear the text input
		}
	};

	return (
		<div>
			<Box sx={{ mb: 2 }}>
				{tags.map((tag, index) => (
					<StyledChip
						key={index}
						label={tag}
						onDelete={() => setTags(tags.filter((t) => t !== tag))}
						sx={{
							mr: "1rem",
							mt: "2rem",
							color: theme.palette.text.dark,
							backgroundColor: theme.palette.primary.main,
						}}
					/>
				))}
			</Box>
			<Autocomplete
				options={results}
				getOptionLabel={(option) => option.name}
				onInputChange={(event, newInputValue) => setQuery(newInputValue)}
				onChange={handleSelect}
				inputValue={query}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						label="Search or create tags..."
						variant="outlined"
						fullWidth
						onKeyDown={handleCreateTag}
						InputLabelProps={{
							style: { color: theme.palette.secondary.main },
						}}
						sx={{
							marginTop: "1rem",
							color: theme.palette.secondary.main,
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&:hover fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.Mui-focused fieldset": {
									borderColor: theme.palette.primary.main,
								},
								"&.MuiAutocomplete-root": {
									backgroundColor: `${theme.palette.background.default} !important`,
								},
							},
							"& .MuiInputBase-input": {
								color: theme.palette.secondary.main,
								backgroundColor: `${theme.palette.background.default} !important`,
							},
							"& input:-webkit-autofill": {
								WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
								WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
							},
						}}
					/>
				)}
			/>
		</div>
	);
};

function CreatePost() {
	const { theme } = useTheme();
	const [width, setWidth] = useState(window.innerWidth);
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const [communityName, setCommunityName] = useState("");
	const [tags, setTags] = useState([]);
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [images, setImages] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const isMobile = width <= 770;
	const navigate = useNavigate();
	const { isLoggedIn, setIsLoggedIn, user, setUser } = useContext(AuthContext);

	// useEffect(() => {
	// 	// This effect will run whenever the user context changes
	// 	if (user) {
	// 		// Perform any actions needed when the user updates
	// 		console.log("User updated:", user);
	// 	}
	// }, [user]);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handleBottomNavChange = (event, newValue) => {
		setBottomNavValue(newValue);
		navigate(`/?tab=${newValue}`);
	};

	const handleImageUpload = (event) => {
		const files = Array.from(event.target.files);
		if (files.length + images.length > 3) {
			setErrorMessage("You can only upload up to 3 images.");
			return;
		}
		setImages([...images, ...files]);
		setErrorMessage("");
	};

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

	const handleSubmit = async () => {
		setUploading(true);
		try {
			const imageUrls = await Promise.all(
				images.map(async (image) => {
					const formData = new FormData();
					formData.append("file", image);
					formData.append("userName", user.username);
					const response = await fetch(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/files/upload`,
						{
							method: "POST",
							headers: {
								Authorization: `Bearer ${user.token}`,
							},
							body: formData,
						}
					);

					const responseText = await response.text();
					console.log("Response Text:", responseText);

					const imageUrl = responseText.split("/uploads/")[1];
					return imageUrl;
				})
			);

			const postData = {
				communityName,
				content,
				imageUrls: imageUrls,
				tags,
				title,
				userId: user.id,
			};

			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/createPost`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(postData),
				}
			);

			if (response.ok) {
				const data = await response.json();
				console.log("Post created:", data);
				const postId = data.postId;
				navigate(`/post/${postId}`);
			} else {
				alert("Failed to create post");
			}
		} catch (error) {
			console.error("Error creating post:", error);
			alert("Failed to create post");
		} finally {
			setUploading(false);
		}
	};

	return (
		<>
			<Box
				sx={{
					pb: isMobile ? 2 : 2,
					pt: isMobile ? 0 : 0,
					mt: 0,
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}>
				<ResponsiveTopBar isMobile={isMobile} />
				{/* <Container className={classes.container}> */}
				<Grid container spacing={2} justifyContent="center">
					<Grid item xs={12} sm={8} justifyContent="center">
						<StyledPaper
							elevation={3}
							sx={{
								backgroundColor: theme.palette.background.default,
							}}>
							<Typography
								variant="h4"
								gutterBottom
								sx={{
									color: theme.palette.text.primary,
								}}>
								Create a New Post
							</Typography>
							<CommunitySearch setCommunityName={setCommunityName} />
							<TagSearch tags={tags} setTags={setTags} />
							<StyledTextField
								label="Title"
								variant="outlined"
								fullWidth
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								InputLabelProps={{
									style: { color: theme.palette.secondary.main },
								}}
								sx={{
									marginTop: "2rem",
									color: theme.palette.secondary.main,
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&:hover fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.Mui-focused fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.MuiAutocomplete-root": {
											backgroundColor: `${theme.palette.background.default} !important`,
										},
									},
									"& .MuiInputBase-input": {
										color: theme.palette.secondary.main,
										backgroundColor: `${theme.palette.background.default} !important`,
									},
									"& input:-webkit-autofill": {
										WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
										WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
									},
								}}
							/>
							<StyledTextField
								label="Content"
								variant="outlined"
								fullWidth
								multiline
								rows={4}
								value={content}
								InputLabelProps={{
									style: { color: theme.palette.secondary.main },
								}}
								onChange={(e) => setContent(e.target.value)}
								sx={{
									marginTop: "2rem",
									color: theme.palette.secondary.main,
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&:hover fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.Mui-focused fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.MuiAutocomplete-root": {
											backgroundColor: `${theme.palette.background.default} !important`,
										},
									},
									"& .MuiInputBase-input": {
										color: theme.palette.secondary.main,
										backgroundColor: `${theme.palette.background.default} !important`,
									},
									"& input:-webkit-autofill": {
										WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
										WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
									},
								}}
								// sx={{
								// 	marginTop: "2rem",
								// 	color: theme.palette.secondary.main,
								// 	"& .MuiOutlinedInput-root": {
								// 		"& fieldset": {
								// 			borderColor: theme.palette.primary.main,
								// 		},
								// 		"&:hover fieldset": {
								// 			borderColor: theme.palette.primary.main,
								// 		},
								// 		"&.Mui-focused fieldset": {
								// 			borderColor: theme.palette.primary.main,
								// 		},
								// 	},
								// 	"& .MuiInputBase-input": {
								// 		color: theme.palette.secondary.main,
								// 		backgroundColor: `${theme.palette.background.default} !important`,
								// 	},
								// }}
							/>
							<StyledButton
								variant="contained"
								component="label"
								sx={{
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.text.dark,
									mt: "2rem",
									"&:hover": {
										backgroundColor: theme.palette.background.darkGreen, // Change background color on hover
										color: theme.palette.text.primary, // Change text color on hover
									},
								}}>
								Upload Images
								<input
									type="file"
									hidden
									multiple
									accept="image/*"
									onChange={handleImageUpload}
								/>
							</StyledButton>
							{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
							<Box sx={{ display: "flex", mt: "2rem" }}>
								{images.map((image, index) => (
									<StyledImagePreview
										key={index}
										src={URL.createObjectURL(image)}
										alt={`preview-${index}`}
										style={{ marginLeft: "1rem" }}
									/>
								))}
							</Box>
							<StyledButton
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={uploading}
								sx={{
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.text.dark,
									mt: "2rem",
									"&:hover": {
										backgroundColor: theme.palette.background.darkGreen, // Change background color on hover
										color: theme.palette.text.primary, // Change text color on hover
									},
								}}>
								{uploading ? <CircularProgress size={24} /> : "Submit Post"}
							</StyledButton>
						</StyledPaper>
					</Grid>
				</Grid>
				{/* </Container> */}
				{isMobile && (
					<BottomNavBar
						bottomNavValue={bottomNavValue}
						onNavChange={handleBottomNavChange}
					/>
				)}
			</Box>
		</>
	);
}

export default CreatePost;
