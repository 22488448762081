import React, { useMemo, useEffect, useState, useContext } from "react";
import { FaArrowUp, FaArrowDown, FaShare, FaComment } from "react-icons/fa";
import "../css/RedditPost.css";
import TagsList from "./TagsList";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { Card, CardMedia } from "@mui/material";
import Utils from "../utils/Utils";
import { useNavigate } from "react-router-dom";
import useVote from "../hooks/useVote";

const PostMain = ({ post, onVote, updatePostVotes }) => {
	const navigate = useNavigate();

	const formattedTime = Utils.getFormattedTime(post.createdAt);

	const { handleVote, upvotes, downvotes } = useVote(
		post,
		(postId, upvotes, downvotes) => {
			post.upvotes = upvotes;
			post.downvotes = downvotes;
		}
	);

	const memoizedImages = useMemo(() => post.images, [post.images]);

	const memoizedCarousel = useMemo(() => {
		if (memoizedImages.length > 0) {
			return (
				<Carousel
					key={post.postId}
					swipe={true}
					height="15rem"
					autoPlay={false}>
					{memoizedImages.map((image) => {
						const imageUrl = `${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${image.url}`;
						return (
							<Card key={image.imageId} sx={{ backgroundColor: "#293b4e" }}>
								<CardMedia
									component="img"
									sx={{ objectFit: "contain", height: "15rem" }}
									image={imageUrl}
									alt={`image-${image.imageId}`}
								/>
							</Card>
						);
					})}
				</Carousel>
			);
		}
		return null;
	}, [memoizedImages, post.postId]);

	return (
		<div className="reddit-post">
			{post.images && post.images.length > 0 && memoizedCarousel}
			<div style={{ marginTop: "1rem" }}>
				<TagsList tags={post.tags} />
			</div>

			<div className="post-info">
				<Link
					to={`/community/${post.communityName}/${post.postId}`}
					style={{ textDecoration: "none" }}>
					<h2
						style={{ textDecoration: "none" }}
						navigate={`/community/${post.communityName}/${post.id}`}>
						{post.title}
					</h2>
				</Link>

				<div
					className="community"
					onClick={() => {
						navigate(`/community/${post.communityName}`);
					}}>
					{"@" + post.communityName}
				</div>
				<span className="time-user">{formattedTime} by &nbsp;</span>
				<span
					className="username"
					onClick={() => {
						navigate(`/profile/${post.userName}`);
					}}>
					{post.userName}
				</span>
				<p className="content" style={{ fontSize: ".9rem" }}>
					<div
						className="clamped-content-3-lines"
						style={{ whiteSpace: "pre-wrap" }}>
						{post.content}
					</div>
				</p>
			</div>
			<div className="interaction-section">
				<FaArrowUp
					className="upvotes-arrow"
					onClick={handleVote(post, "UPVOTE")}
				/>
				<span className="upvotes">{Utils.abbreviateNumber(post.upvotes)}</span>
				<FaArrowDown
					className="downvotes-arrow"
					onClick={handleVote(post, "DOWNVOTE")}
				/>
				<span className="downvotes">
					{Utils.abbreviateNumber(post.downvotes)}
				</span>
				<FaComment
					className="interaction-icon"
					onClick={() =>
						navigate(`/community/${post.communityName}/${post.postId}`)
					}
				/>
				<span className="comments">
					{Utils.abbreviateNumber(post.commentCount)}
				</span>
				<FaShare className="share" />
			</div>
		</div>
	);
};

export default PostMain;
