import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CommunityLeftSidebar from "../components/CommunityLeftSidebar";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import {
	AdvancedChart,
	FundamentalData,
	SymbolInfo,
	CompanyProfile,
} from "react-tradingview-embed";
import AdvancedChart2 from "../components/AdvancedChart2";
import { useTheme } from "../components/ThemeContext";
import PostCommunity from "../components/PostCommunity";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import BottomNavBarRouter from "../components/BottomNavBarRouter";
import axios from "axios";
import { throttle } from "lodash";

const StyledBox = styled(Box)(({ theme }) => ({
	root: {
		width: "100%",
		position: "fixed",
		bottom: 0,
		backgroundColor: "#080D1B",
		border: 4,
		borderColor: "#202F40",
		color: "#080D1B",
	},
	actionItem: {
		"& .MuiBottomNavigationAction-root": {
			color: "#61F977",
		},
		"& .MuiBottomNavigationAction-label": {
			color: "#FCFAD8",
		},
		"& .Mui-selected": {
			color: "#61F977",
		},
	},
}));

// Memoized ChartSection component
const ChartSection = React.memo(({ stockTicker, isMobile }) => {
	return (
		<div>
			<AdvancedChart2 stockTicker={stockTicker} isMobile={isMobile} />
		</div>
	);
});

// Memoized RightStocksBar component
const RightStocksBar = React.memo(({ stockTicker, isMobile }) => {
	return (
		<>
			<SymbolInfo
				widgetProps={{
					symbol: stockTicker.replace("-", ":"),
					width: isMobile ? "100%" : 400,
					height: 550,
					locale: "en",
					isTransparent: true,
				}}
			/>
			<div style={{ marginTop: "20px" }}>
				<FundamentalData
					widgetProps={{
						isTransparent: true,
						largeChartUrl: "",
						displayMode: "regular",
						width: isMobile ? "100%" : 400,
						height: 550,
						colorTheme: "dark",
						symbol: stockTicker.replace("-", ":"),
						locale: "en",
					}}
				/>
			</div>
			<div style={{ marginTop: "20px" }}>
				<CompanyProfile
					widgetProps={{
						width: isMobile ? "100%" : 400,
						height: 550,
						isTransparent: true,
						colorTheme: "dark",
						symbol: stockTicker.replace("-", ":"),
						locale: "en",
					}}
				/>
			</div>
		</>
	);
});

function StocksPage() {
	const { stockTicker } = useParams(); // Accessing the URL parameter
	const [width, setWidth] = useState(window.innerWidth);
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const isMobile = width <= 770;
	const [value, setValue] = useState("1");
	const { theme } = useTheme();

	const [posts, setPosts] = useState([]);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const loadingRef = useRef(false); // Use ref for synchronous loading state

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchPosts = useCallback(async () => {
		if (loadingRef.current || !hasMore) return; // Prevent multiple requests
		loadingRef.current = true; // Set loadingRef.current to true immediately
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/byCommunityOrTags`,
				{
					params: {
						communityName: stockTicker,
						tags: stockTicker,
						sortBy: "hot",
						page: page,
						size: { isMobile } ? 4 : 7,
					},
				}
			);
			const data = response.data;
			setPosts((prevPosts) => {
				const newPosts = data.content.filter(
					(post) => !prevPosts.some((p) => p.postId === post.postId)
				);
				return [...prevPosts, ...newPosts];
			});
			setHasMore(!data.last);
		} catch (error) {
			console.error("Error fetching posts:", error);
		} finally {
			loadingRef.current = false; // Reset loadingRef.current after request
		}
	}, [page, stockTicker, hasMore]);

	useEffect(() => {
		fetchPosts();
	}, [page, fetchPosts]);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const handleScroll = throttle(() => {
			if (
				window.innerHeight + document.documentElement.scrollTop >=
				document.documentElement.offsetHeight * 0.7
			) {
				if (hasMore && !loadingRef.current) {
					setPage((prevPage) => prevPage + 1);
				}
			}
		}, 300); // Adjust throttle delay as needed

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [hasMore]);

	return (
		<>
			<ResponsiveTopBar isMobile={isMobile} />
			{isMobile ? (
				<Box>
					<Box sx={{ width: "100%", typography: "body1" }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "divider",
									marginLeft: "1rem",
								}}>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example">
									<Tab
										label="Posts"
										value="1"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
									<Tab
										label="Trending here"
										value="2"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
									<Tab
										label="Stock Info"
										value="3"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
								</TabList>
							</Box>
							<TabPanel value="1">
								{posts.map((post) => (
									<PostCommunity key={post.postId} post={post} />
								))}
							</TabPanel>
							<TabPanel value="2">
								<CommunityLeftSidebar />
							</TabPanel>
							<TabPanel value="3">
								<ChartSection stockTicker={stockTicker} isMobile={isMobile} />
								<div style={{ marginTop: "1rem" }}>
									<RightStocksBar
										stockTicker={stockTicker}
										isMobile={isMobile}
									/>
								</div>
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
			) : (
				<Grid
					container
					spacing={1}
					columns={10}
					justifyContent="space-evenly"
					alignItems="flex-start">
					<Grid item xs={1.5}>
						<CommunityLeftSidebar />
					</Grid>
					<Grid item xs={4}>
						<ChartSection stockTicker={stockTicker} isMobile={isMobile} />
						{posts.map((post) => (
							<PostCommunity key={post.postId} post={post} />
						))}
					</Grid>
					<Grid item xs={3}>
						<RightStocksBar stockTicker={stockTicker} isMobile={isMobile} />
					</Grid>
				</Grid>
			)}
			{isMobile && <BottomNavBarRouter bottomNavValue={bottomNavValue} />}
		</>
	);
}

export default StocksPage;
