import React, { useState, useEffect } from "react";
import {
	Box,
	BottomNavigation,
	BottomNavigationAction,
	Grid,
} from "@mui/material";
import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import { samplePosts } from "../content/post_content/samplePosts";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import PostCommunity from "../components/PostCommunity";
import ProfileComponent from "../components/ProfileComponent";
import { useParams } from "react-router-dom";
import BottomNavBarRouter from "../components/BottomNavBarRouter";

const ProfilePage = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const { username } = useParams();
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const isMobile = width <= 770;

	useEffect(() => {
		const handleWindowSizeChange = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleWindowSizeChange);

		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const handleBottomNavChange = (event, newValue) => {
		setBottomNavValue(newValue);
	};

	const renderMobileBottomNavContent = (bottomNavValue) => {
		switch (bottomNavValue) {
			case 0:
				return <LeftSidebar />;
			case 1:
				return (
					<Grid container spacing={2}>
						{samplePosts.map((post) => (
							<Grid item xs={12} key={post.id}>
								<PostCommunity post={post} />
							</Grid>
						))}
					</Grid>
				);
			case 2:
				return <RightSidebar />;
			default:
				return null;
		}
	};

	return (
		<Box
			sx={{
				pb: isMobile ? 2 : 2,
				pt: isMobile ? 0 : 0,
				mt: 0,
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}>
			<ResponsiveTopBar isMobile={isMobile} />

			<div>
				<ProfileComponent username={username} />
			</div>
			{isMobile && (
				<div>
					<BottomNavBarRouter bottomNavValue={bottomNavValue} />
				</div>
			)}
		</Box>
	);
};

export default ProfilePage;
