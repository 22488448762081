import { createContext, useContext, useState } from "react";
import { createTheme } from "@mui/material/styles";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState("dark");

	const themeConfig = createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "#62F481",
			},
			secondary: {
				main: "#f9f9cf",
			},
			text: {
				primary: "#F9F9CF",
				secondary: "#f9f9cf",
				green: "#62F481",
				disabled: "#FF4500",
				dark: "#0C192B",
			},
			tags: {
				index: "#e6e666",
				crypto: "#7e90ea",
				stock: "#62F481",
				community: "#177bcc",
			},
			background: {
				paper: "#0a1423",
				highlight: "#101a50",
				default: "#060D1D",
				unread: "#060d1dbb",
				read: "#060d1d00",
				gray: "#1E222D",
				darkGreen: "#31bb4f",
				cancel: "#ff5e39ff",
				cancelHighlight: "#c33906ff",
			},
			success: {
				main: "#418e52",
			},
			error: {
				main: "#FF4500",
			},
		},
	});

	return (
		<ThemeContext.Provider
			value={{
				theme: themeConfig,
				toggleTheme: () =>
					setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light")),
			}}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
