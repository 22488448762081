import Chip from "@mui/material/Chip";
import React from "react";
import { useTheme } from "./ThemeContext";
import { Link } from "react-router-dom";

const TagsList = ({ tags, marginTop }) => {
	const { theme } = useTheme();
	const getTagColor = (tag) => {
		if (tag.startsWith("c/")) {
			return theme.palette.tags.community;
		} else if (tag.startsWith("Crypto:")) {
			return theme.palette.tags.crypto;
		} else if (tag.startsWith("index/")) {
			return theme.palette.tags.index;
		} else {
			return theme.palette.tags.stock;
		}
	};
	return (
		<span>
			{tags.map((tag, index) => (
				<Chip
					size="small"
					label={tag.split(":").length > 1 ? tag.split(":")[1] : tag}
					clickable
					key={index}
					component={Link}
					to={tag.startsWith("c/") ? "/" + tag : "/s/" + tag}
					sx={{
						backgroundColor: getTagColor(tag),
						"&:hover": {
							background: "#13ffac",
						},
						marginLeft: "1rem",
						marginTop: 0,
						color: theme.palette.text.dark,
					}}
				/>
			))}
		</span>
	);
};
export default TagsList;
