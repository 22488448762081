import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {PositionsSummary} from "../content/post_content/PositionsSummary";
import {useTheme} from "./ThemeContext";



export default function AnalysisTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const {theme} = useTheme();

  const columns = [
    { id: 'position',
      label: 'Position',
      minWidth: 100,
      formatColor:  (value) =>
        {
          value= value.toString()
          if (value.charAt(0)=== "+" || value === "Long") {
            return theme.palette.text.green;}
          else if (value.charAt(0) === "-" || value==="Short") {
            return theme.palette.text.disabled;}
          else {
            return theme.palette.text.primary;}
        }
        },
    { id: 'stock', label: 'Stock', minWidth: 100,
      formatColor:  (value) =>
        {
          value= value.toString()
          if (value.charAt(0) === "+") {
            return theme.palette.text.green;}
          else if (value.charAt(0) === "-") {
            return theme.palette.text.disabled;}
          else {
            return theme.palette.text.primary;}
        }
      },
    {
      id: 'title',
      label: 'Post Title',
      minWidth: 170,
      align: 'left',
      formatColor:  (value) =>
      {
        value= value.toString()
        if (value.charAt(0) === "+") {
          return theme.palette.text.green;}
        else if (value.charAt(0) === "-") {
          return theme.palette.text.disabled;}
        else {
          return theme.palette.text.primary;}
      }
    },
    {
      id: 'postDate',
      label: 'Date Posted',
      minWidth: 100,
      align: 'center',
      format: (value) => value.toDate('en-US'),
      formatColor:  (value) =>
      {
        value= value.toString()
        if (value.charAt(0) === "+") {
          return theme.palette.text.green;}
        else if (value.charAt(0) === "-") {
          return theme.palette.text.disabled;}
        else {
          return theme.palette.text.primary;}
      }
    },
    {
      id: 'priceAtPost',
      label: 'Price at Post',
      minWidth: 100,
      align: 'center',
      format: (value) => value.toFixed(2),
      formatColor:  (value) =>
      {
        value= value.toString()
        if (value.charAt(0) === "+") {
          return theme.palette.text.green;}
        else if (value.charAt(0) === "-") {
          return theme.palette.text.disabled;}
        else {
          return theme.palette.text.primary;}
      }
    },
    {
      id: 'currentPrice',
      label: 'Current Price',
      minWidth: 100,
      align: 'center',
      format: (value) => value.toFixed(2),
      formatColor:  (value) =>
      {
        value= value.toString()
        if (value.charAt(0) === "+") {
          return theme.palette.text.green;}
        else if (value.charAt(0) === "-") {
          return theme.palette.text.disabled;}
        else {
          return theme.palette.text.primary;}
      }
    },
    {
      id: 'Gain',
      label: 'Gain',
      minWidth: 100,
      align: 'center',
      format: (value) => value.toFixed(2),
      formatColor:  (value) =>
        {
          value= value.toString()
          if (value.charAt(0) === "+") {
              return theme.palette.text.green;}
          else if (value.charAt(0) === "-") {
              return theme.palette.text.disabled;}
          else {
              return theme.palette.text.primary;}
        }
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor:theme.palette.background.paper }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,
                    backgroundColor:theme.palette.background.gray,
                    fontWeight: 'bold',
                    color:theme.palette.text.green }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {PositionsSummary
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{color: column.formatColor(value)}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={PositionsSummary.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        color="primary"
        style={{color:theme.palette.text.green, backgroundColor:theme.palette.background.gray}}
      />
    </Paper>
  );
}