import React, { useState, useEffect } from "react";
import {
	BottomNavigation,
	BottomNavigationAction,
	IconButton,
	Tooltip,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Groups3Icon from "@mui/icons-material/Groups3";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { useTheme } from "./ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
	width: "100%",
	position: "fixed",
	bottom: 0,
	zIndex: 1000, // Ensure it's above other elements
	backgroundColor: theme.palette.background.paper,
	borderTop: `2px solid ${theme.palette.background.gray}`,
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
	({ theme }) => ({
		color: "#61F977",
		"& .MuiBottomNavigationAction-root": {
			color: "#61F977",
		},
		"& .MuiBottomNavigationAction-label": {
			color: "#f9f9cf",
		},
		"& .Mui-selected": {
			color: "#61F977",
		},
	})
);

function BottomNavBar() {
	const { theme } = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const tabIndex = parseInt(queryParams.get("tab")) ?? 1;
	const [bottomNavValue, setBottomNavValue] = useState(tabIndex);
	const [openCreateDialog, setOpenCreateDialog] = useState(false);

	const handleOpenCreateDialog = () => {
		setOpenCreateDialog(true);
	};

	const handleCloseCreateDialog = () => {
		setOpenCreateDialog(false);
	};

	useEffect(() => {
		let currentTab = parseInt(queryParams.get("tab"));
		if (isNaN(currentTab)) {
			currentTab = 1;
		}
		if (
			location.pathname !== "/createPost" &&
			location.pathname !== "/createCommunity"
		) {
			setBottomNavValue(currentTab);
		}
	}, [location.search, navigate, location.pathname]);

	const handleBottomNavChange = (event, newValue) => {
		if (newValue !== 3) {
			if (
				location.pathname !== "/" ||
				queryParams.get("tab") !== `${newValue}`
			) {
				setBottomNavValue(newValue);
				navigate(`/?tab=${newValue}`);
			}
		} else {
			handleOpenCreateDialog();
		}
	};

	return (
		<>
			<StyledBottomNavigation
				value={bottomNavValue}
				onChange={handleBottomNavChange}
				showLabels
				sx={{
					backgroundColor: theme.palette.background.paper,
					borderTop: `2px solid ${theme.palette.background.gray}`,
				}}>
				<StyledBottomNavigationAction
					label="Communities"
					icon={
						<Groups3Icon
							sx={{
								color:
									bottomNavValue === 0
										? theme.palette.primary.main
										: theme.palette.secondary.main,
							}}
						/>
					}
					sx={{
						color:
							bottomNavValue === 0
								? theme.palette.primary.main
								: theme.palette.secondary.main,
					}}
				/>
				<StyledBottomNavigationAction
					label="Feed"
					icon={
						<HomeIcon
							sx={{
								color:
									bottomNavValue === 1
										? theme.palette.primary.main
										: theme.palette.secondary.main,
							}}
						/>
					}
					sx={{
						color:
							bottomNavValue === 1
								? theme.palette.primary.main
								: theme.palette.secondary.main,
					}}
				/>
				<StyledBottomNavigationAction
					label="Stocks"
					icon={
						<ShowChartIcon
							sx={{
								color:
									bottomNavValue === 2
										? theme.palette.primary.main
										: theme.palette.secondary.main,
							}}
						/>
					}
					sx={{
						color:
							bottomNavValue === 2
								? theme.palette.primary.main
								: theme.palette.secondary.main,
					}}
				/>
				<StyledBottomNavigationAction
					label="Create"
					icon={
						<Tooltip title="Create">
							<IconButton
								onClick={handleOpenCreateDialog}
								sx={{
									p: 0,
								}}>
								<AddIcon
									sx={{
										color: theme.palette.secondary.main,
									}}
								/>
							</IconButton>
						</Tooltip>
					}
					sx={{
						color: theme.palette.primary.main,
					}}
				/>
			</StyledBottomNavigation>
			<Dialog
				open={openCreateDialog}
				onClose={handleCloseCreateDialog}
				aria-labelledby="create-dialog-title"
				sx={{
					"& .MuiDialog-paper": {
						width: "400px", // Adjust the width as needed
						border: `1px solid ${theme.palette.background.gray}`,
						backgroundColor: theme.palette.background.paper,
					},
				}}>
				<DialogTitle
					id="create-dialog-title"
					sx={{
						color: theme.palette.secondary.main,
						textAlign: "center",
						backgroundColor: theme.palette.background.paper,
					}}>
					Create
				</DialogTitle>
				<DialogContent
					sx={{
						backgroundColor: theme.palette.background.paper,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Button
						variant="contained"
						onClick={() => {
							navigate("/createPost");
							handleCloseCreateDialog();
						}}
						size="medium"
						sx={{
							marginBottom: ".5rem",
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.text.dark,
							textTransform: "none",
							"&:hover": {
								backgroundColor: theme.palette.background.darkGreen,
								color: theme.palette.text.primary,
							},
						}}>
						Post
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							navigate("/createCommunity");
							handleCloseCreateDialog();
						}}
						size="medium"
						sx={{
							marginBottom: ".5rem",
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.text.dark,
							textTransform: "none",
							"&:hover": {
								backgroundColor: theme.palette.background.darkGreen,
								color: theme.palette.text.primary,
							},
						}}>
						Community
					</Button>
				</DialogContent>
				<DialogActions
					sx={{
						backgroundColor: theme.palette.background.paper,
						justifyContent: "center",
					}}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCloseCreateDialog}
						size="small"
						sx={{
							marginBottom: ".5rem",
							color: theme.palette.text.dark,
							backgroundColor: theme.palette.background.cancel,
							borderColor: theme.palette.background.cancel,
							textTransform: "none",
							"&:hover": {
								backgroundColor: theme.palette.background.cancelHighlight,
								borderColor: theme.palette.background.cancelHighlight,
								color: theme.palette.text.primary,
							},
						}}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default BottomNavBar;
