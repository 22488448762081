import React, { useState, useContext, useEffect } from "react";
import { Box, Button, TextField, Typography, Container } from "@mui/material";
import Alert from "@mui/material/Alert";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "../components/ThemeContext";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import cookies from "js-cookie";

function SignInSignUpPage() {
	const [isSignUp, setIsSignUp] = useState(false);
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [passwordConditionsMet, setPasswordConditionsMet] = useState(true);
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [usernameConditionsMet, setUsernameConditionsMet] = useState(true);
	const [usernameError, setUsernameError] = useState("");
	const [width, setWidth] = useState(window.innerWidth);
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const navigate = useNavigate(); // Initialize navigate
	const { signOut, signIn } = useContext(AuthContext);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const isMobile = width <= 770;

	const toggleSignUp = () => {
		setIsSignUp(!isSignUp);
		setEmail("");
		setUsername("");
		setPassword("");
		setConfirmPassword("");
		setPasswordError("");
		setConfirmPasswordError("");
		setErrorMessage("");
		setSuccessMessage("");
		setShowPassword(false);
	};

	const handleUsernameChange = (event) => {
		const newUsername = event.target.value;
		setUsername(newUsername);

		const allUsernameConditionsMet = usernameConditions.every(
			(condition) => condition.isValid
		);
		setUsernameConditionsMet(allUsernameConditionsMet);

		if (!allUsernameConditionsMet) {
			setUsernameError(
				"Username must be 3-15 characters long, contain only alphanumeric characters and underscores, and have no spaces."
			);
		} else {
			setUsernameError("");
		}
	};

	const handleEmailChange = (event) => setEmail(event.target.value);

	const handlePasswordChange = (event) => {
		const newPassword = event.target.value;
		setPassword(newPassword);

		const passwordCriteria = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
		if (!passwordCriteria.test(newPassword)) {
			setPasswordError(
				"Password must be at least 8 characters long, contain an uppercase letter, and a symbol."
			);
		} else {
			setPasswordError("");
		}
	};

	const handleConfirmPasswordChange = (event) => {
		const newConfirmPassword = event.target.value;
		setConfirmPassword(newConfirmPassword);

		if (newConfirmPassword !== password) {
			setConfirmPasswordError("Passwords do not match.");
		} else {
			setConfirmPasswordError("");
		}
	};

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const allConditionsMet = passwordConditions.every(
			(condition) => condition.isValid
		);
		setPasswordConditionsMet(allConditionsMet);

		if (isSignUp) {
			if (!allConditionsMet) {
				setErrorMessage(
					"Please change the password to meet all criteria. Please try again."
				);
				return;
			}
			if (password !== confirmPassword) {
				setErrorMessage("Passwords do not match. Please try again.");
				setPasswordsMatch(false);
				return;
			}

			const allUsernameConditionsMet = usernameConditions.every(
				(condition) => condition.isValid
			);
			setUsernameConditionsMet(allUsernameConditionsMet);

			if (!allUsernameConditionsMet) {
				setErrorMessage(
					"Please change the username to meet all criteria. Please try again."
				);
				return;
			}

			setErrorMessage("");

			const url = `${process.env.REACT_APP_SC_API_BASE_URL}/api/auth/signup`;

			const payload = { email: email, username: username, password: password };

			try {
				const response = await axios.post(url, payload);

				if (response.status === 200) {
					const data = response.data;
					console.log("Success:", data);

					//Redirect to the sign in page
					toggleSignUp();
					setSuccessMessage("User created successfully! Please sign in.");
				} else {
					throw new Error("Failed to create user");
				}
			} catch (error) {
				const errorMessage =
					error.response && error.response.data && error.response.data.message
						? error.response.data.message
						: "Failed to submit. Please try again.";
				console.error(`${errorMessage}:`, error);
				setErrorMessage(errorMessage);
			}
		} else {
			const url = `${process.env.REACT_APP_SC_API_BASE_URL}/api/auth/signin`;
			const payload = { username: username, password: password };

			try {
				const response = await axios.post(url, payload);

				if (response.status === 200) {
					const data = response.data;
					console.log("Success:", data);

					const userData = {
						token: data.accessToken, // Replace with actual token from your authentication logic
						id: data.id,
						username: data.username,
						email: data.email,
						avatarUrl: data.avatarUrl,
						bannerUrl: data.bannerUrl,
						roles: data.roles,
						tokenType: data.tokenType,
					};
					signIn(userData);
					setSuccessMessage(
						"You have successfully signed in!. You will soon be redirected."
					);

					// Retrieve the last visited route from local storage
					let lastVisitedRoute =
						localStorage.getItem("lastVisitedRoute") || "/";
					if (lastVisitedRoute === "/signin") {
						lastVisitedRoute = "/";
					}

					navigate(lastVisitedRoute);
				} else {
					throw new Error("Failed to sign in");
				}
			} catch (error) {
				const errorMessage =
					error.response && error.response.data && error.response.data.message
						? error.response.data.message
						: "Failed to submit. Please try again.";

				console.error(`${errorMessage}:`, error);
				setErrorMessage(errorMessage);
			}
		}
	};

	const { theme } = useTheme();

	const passwordConditions = [
		{ label: "At least 8 characters", isValid: password.length >= 8 },
		{ label: "Contains a number", isValid: /\d/.test(password) },
		{ label: "Contains an uppercase letter", isValid: /[A-Z]/.test(password) },
		{ label: "Contains a lowercase letter", isValid: /[a-z]/.test(password) },
		{
			label: "Contains a special character",
			isValid: /[!@#$%^&*]/.test(password),
		},
	];

	const usernameConditions = [
		{
			label: "3 to 15 characters",
			isValid: username.length >= 3 && username.length <= 15,
		},
		{
			label: "Only alphanumeric characters and underscores",
			isValid: /^[a-zA-Z0-9_]+$/.test(username),
		},
		{ label: "No spaces", isValid: !/\s/.test(username) },
	];

	return (
		<Box
			sx={{
				pb: isMobile ? 2 : 2,
				pt: isMobile ? 0 : 0,
				mt: 0,
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}>
			<ResponsiveTopBar isMobile={isMobile} />
			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: theme.palette.secondary.main,
					}}>
					<Typography
						component="h1"
						variant="h5"
						color={theme.palette.secondary.main}>
						{isSignUp ? "Sign Up" : "Sign In"}
					</Typography>
					{successMessage && (
						<Alert variant="filled" severity="success" sx={{ marginTop: 2 }}>
							{successMessage}
						</Alert>
					)}

					<Box
						component="form"
						noValidate
						sx={{ mt: 1 }}
						onSubmit={handleSubmit}>
						{isSignUp && (
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								autoComplete="email"
								value={email}
								onChange={handleEmailChange}
								InputLabelProps={{
									style: { color: theme.palette.secondary.main },
								}}
								sx={{
									color: theme.palette.secondary.main,
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&:hover fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.Mui-focused fieldset": {
											borderColor: theme.palette.primary.main,
										},
										"&.MuiAutocomplete-root": {
											backgroundColor: `${theme.palette.background.default} !important`,
										},
									},
									"& .MuiInputBase-input": {
										color: theme.palette.secondary.main,
										backgroundColor: `${theme.palette.background.default} !important`,
									},
									"& input:-webkit-autofill": {
										WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
										WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
									},
								}}
							/>
						)}

						<TextField
							margin="normal"
							required
							fullWidth
							id="username"
							label="Username"
							name="username"
							autoComplete="username"
							value={username}
							onChange={handleUsernameChange}
							error={isSignUp && !!usernameError}
							helperText={isSignUp && !usernameError}
							InputLabelProps={{
								style: { color: theme.palette.secondary.main },
							}}
							sx={{
								color: theme.palette.secondary.main,
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&:hover fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&.Mui-focused fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&.MuiAutocomplete-root": {
										backgroundColor: `${theme.palette.background.default} !important`,
									},
								},
								"& .MuiInputBase-input": {
									color: theme.palette.secondary.main,
									backgroundColor: `${theme.palette.background.default} !important`,
								},
								"& input:-webkit-autofill": {
									WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
									WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
								},
							}}
						/>
						{isSignUp && (
							<Box sx={{ mt: 2 }}>
								{usernameConditions.map((condition, index) => (
									<Typography
										key={index}
										variant="body2"
										color={condition.isValid ? "green" : "red"}>
										{condition.isValid ? <CheckIcon /> : <CloseIcon />}{" "}
										{condition.label}
									</Typography>
								))}
							</Box>
						)}

						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type={showPassword ? "text" : "password"}
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={
								isSignUp
									? handlePasswordChange
									: (e) => setPassword(e.target.value)
							}
							error={isSignUp && !!passwordError}
							helperText={isSignUp && passwordError}
							InputLabelProps={{
								style: { color: theme.palette.secondary.main },
							}}
							InputProps={{
								// <-- This is where the toggle button is added.
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}>
											{showPassword ? (
												<Visibility
													sx={{ color: theme.palette.primary.main }}
												/>
											) : (
												<VisibilityOff
													sx={{ color: theme.palette.primary.main }}
												/>
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
							sx={{
								color: theme.palette.secondary.main,
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&:hover fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&.Mui-focused fieldset": {
										borderColor: theme.palette.primary.main,
									},
									"&.MuiAutocomplete-root": {
										backgroundColor: `${theme.palette.background.default} !important`,
									},
								},
								"& .MuiInputBase-input": {
									color: theme.palette.secondary.main,
									backgroundColor: `${theme.palette.background.default} !important`,
								},
								"& input:-webkit-autofill": {
									WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
									WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
								},
							}}
						/>
						{isSignUp && (
							<>
								<Box sx={{ mt: 2 }}>
									{passwordConditions.map((condition, index) => (
										<Typography
											key={index}
											variant="body2"
											color={condition.isValid ? "green" : "red"}>
											{condition.isValid ? <CheckIcon /> : <CloseIcon />}{" "}
											{condition.label}
										</Typography>
									))}
								</Box>
								<TextField
									margin="normal"
									required
									fullWidth
									name="confirmPassword"
									label="Confirm Password"
									type={showPassword ? "text" : "password"}
									id="confirmPassword"
									autoComplete="current-password"
									value={confirmPassword}
									onChange={handleConfirmPasswordChange}
									error={!!confirmPasswordError}
									helperText={confirmPasswordError}
									InputLabelProps={{
										style: { color: theme.palette.secondary.main },
									}}
									InputProps={{
										// <-- This is where the toggle button is added.
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}>
													{showPassword ? (
														<Visibility
															sx={{ color: theme.palette.primary.main }}
														/>
													) : (
														<VisibilityOff
															sx={{ color: theme.palette.primary.main }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									sx={{
										color: theme.palette.secondary.main,
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: theme.palette.primary.main,
											},
											"&:hover fieldset": {
												borderColor: theme.palette.primary.main,
											},
											"&.Mui-focused fieldset": {
												borderColor: theme.palette.primary.main,
											},
											"&.MuiAutocomplete-root": {
												backgroundColor: `${theme.palette.background.default} !important`,
											},
										},
										"& .MuiInputBase-input": {
											color: theme.palette.secondary.main,
											backgroundColor: `${theme.palette.background.default} !important`,
										},
										"& input:-webkit-autofill": {
											WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
											WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
										},
									}}
								/>
							</>
						)}
						{errorMessage && ( // Add this block
							<Alert variant="filled" severity="error" sx={{ borderTop: 2 }}>
								{errorMessage}
							</Alert>
						)}
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.text.dark,
								textTransform: "none",
								mt: 3,
								mb: 2,
								"&:hover": {
									backgroundColor: theme.palette.background.darkGreen, // Change background color on hover
									color: theme.palette.text.primary, // Change text color on hover
								},
							}}>
							{isSignUp ? "Sign Up" : "Sign In"}
						</Button>
						<Button
							fullWidth
							variant="text"
							onClick={toggleSignUp}
							sx={{
								color: theme.palette.secondary.main,
								textTransform: "none",
							}}>
							{isSignUp
								? "Already have an account? Sign In"
								: "Don't have an account? Sign Up"}
						</Button>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}

export default SignInSignUpPage;
