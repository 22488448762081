import React from "react";
import { styled } from "@mui/material/styles";
import CommunityCard from "./CommunityCard";
import "./HotCommunities.css"; // Make sure to create a corresponding CSS file
import "./RightSidebar.css"; // Make sure to create a corresponding CSS file

import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTheme } from "./ThemeContext";
import { ThemeProvider } from "@mui/system";

const SidebarContainer = styled("div")(({ theme }) => ({
	width: "auto",
	backgroundColor: "rgba(255, 255=, 255, 0.1)", // Your desired background color for the sidebar
	color: "white",
	borderRadius: theme.shape.borderRadius,
	overflow: "hidden",
	marginLeft: "15px",
}));

const CardContainer = styled("div")({
	marginTop: "10px", // Adjust the margin value as per your requirement
});

// Mock data for top analysts
const topAnalysts = [
	{ name: "u/InvestingKitten", letter_logo: "IK" },
	{ name: "u/JimCraimer", letter_logo: "JC" },
	{ name: "u/futuresTrader89", letter_logo: "FT" },
	// ...more analysts
];
const topComments = [
	{ name: "u/ZeroToHero", letter_logo: "ZH" },
	{ name: "u/LostMyPants", letter_logo: "LM" },
	{ name: "u/WinnerWinnerChickenDinner", letter_logo: "WC" },
	// ...more analysts
];

const topPosts = [
	{
		link: "post/10",
		name: "Crypto Bros: Is $BTC Back or Just Another Tease?",
		icon: "🔥",
	},
	{
		link: "post/11",
		name: "Options Trading 101: Understanding Greeks",
		icon: "📈",
	},
	{
		link: "post/14",
		name: "Stonks Only Go Up, Right? $ROKU Disagrees",
		icon: "💰",
	},
];

function CommunityLeftSidebar({ communityName }) {
	const { theme } = useTheme();
	return (
		<ThemeProvider theme={theme}>
			<SidebarContainer>
				<Grid container spacing={0} direction="column" alignItems="left">
					<Grid item xs={6}>
						<div
							style={{
								backgroundColor: theme.palette.background.default,
								marginTop: 15,
							}}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: ".5rem",
									}}>
									Trending Posts in {communityName}
								</Box>

								{topPosts.map((community, index) => (
									<CommunityCard
										key={community.name}
										link={community.link}
										name={community.name}
										icon={community.icon}
									/>
								))}
							</Box>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: "0.5rem",
									}}>
									Trending Users in {communityName}
								</Box>

								{topAnalysts.map((analyst) => (
									<CommunityCard
										key={analyst.name}
										name={analyst.name}
										letter_logo={analyst.letter_logo}
									/>
								))}
							</Box>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Box
								sx={{
									bgcolor: "background.default",
									boxShadow: 1,
									borderRadius: 1,
									p: 2,
									minWidth: 300,
								}}>
								<Divider
									color="secondary"
									sx={{
										marginBottom: "1rem",
										color: "secondary",
										marginLeft: "-.3rem",
									}}
								/>
								<Box
									sx={{
										color: "text.secondary",
										fontSize: 18,
										fontWeight: "medium",
										margin: "1.5rem",
										alignItems: "left",
										marginLeft: "0.5rem",
									}}>
									Trending Comments in {communityName}
								</Box>

								{topComments.map((analyst) => (
									<CommunityCard
										key={analyst.name}
										name={analyst.name}
										letter_logo={analyst.letter_logo}
									/>
								))}
							</Box>
						</div>
					</Grid>
				</Grid>
			</SidebarContainer>
		</ThemeProvider>
	);
}

export default CommunityLeftSidebar;
