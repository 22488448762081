import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PositionsSummary } from "../content/post_content/PositionsSummary";
import { useTheme } from "./ThemeContext";

export default function PositionsTable() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const { theme } = useTheme();

	const columns = [
		{
			id: "position",
			label: "Position",
			minWidth: "1rem",
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+" || value === "Long") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-" || value === "Short") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
		{
			id: "stock",
			label: "Stock",
			minWidth: "1rem",
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
		{
			id: "postDate",
			label: "Date Aquired",
			minWidth: "1rem",
			align: "center",
			format: (value) => value.toDate("en-US"),
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
		{
			id: "priceAtPost",
			label: "Acquired",
			minWidth: "1rem",
			align: "center",
			format: (value) => "$" + value.toFixed(2),
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
		{
			id: "currentPrice",
			label: "Current",
			minWidth: "1rem",
			align: "center",
			format: (value) => "$" + value.toFixed(2),
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
		{
			id: "Gain",
			label: "Gain",
			minWidth: "1rem",
			align: "center",
			format: (value) => value.toFixed(2),
			formatColor: (value) => {
				value = value.toString();
				if (value.charAt(0) === "+") {
					return theme.palette.text.green;
				} else if (value.charAt(0) === "-") {
					return theme.palette.text.disabled;
				} else {
					return theme.palette.text.primary;
				}
			},
		},
	];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const filteredData = PositionsSummary.slice(0, 1);

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				backgroundColor: theme.palette.background.paper,
				borderRadius: "1rem",
				borderColor: theme.palette.primary.main,
				borderWidth: "1px",
			}}>
			<TableContainer sx={{ maxHeight: "10rem" }}>
				<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow sx={{ height: "0.1rem" }}>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									sx={{
										margin: "0rem",
										minWidth: column.minWidth,
										backgroundColor: theme.palette.background.gray,
										fontWeight: "bold",
										color: theme.palette.text.green,
									}}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredData.map((row) => {
							return (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={row.id}
									sx={{ height: "0.1rem" }}>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ color: column.formatColor(value) }}>
												{column.format && typeof value === "number"
													? column.format(value)
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
