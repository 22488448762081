import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const token = Cookies.get("accessToken");
		if (token) {
			try {
				const fetchedUserString = Cookies.get("user");
				if (fetchedUserString) {
					const fetchedUser = JSON.parse(fetchedUserString);
					fetchedUser.token = token;
					setUser(fetchedUser);
					setIsLoggedIn(true);
				}
			} catch (error) {
				console.error("Failed to parse user data from cookies", error);
			}
		}
	}, []);

	const signIn = (userData) => {
		const { token, ...user } = userData;
		Cookies.set("accessToken", token, {
			expires: 7,
			secure: true,
			sameSite: "Lax",
		});
		Cookies.set("user", JSON.stringify(user), {
			expires: 7,
			secure: true,
			sameSite: "Lax",
		});
		setUser({ ...user, token });
		setIsLoggedIn(true);
	};

	const signOut = () => {
		Cookies.remove("accessToken");
		Cookies.remove("user");
		setUser(null);
		setIsLoggedIn(false);
	};

	return (
		<AuthContext.Provider
			value={{ isLoggedIn, setIsLoggedIn, user, setUser, signIn, signOut }}>
			{children}
		</AuthContext.Provider>
	);
};
