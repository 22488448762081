import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function RouteTracker() {
    const location = useLocation();
    const { isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        if (isLoggedIn) {
            localStorage.setItem('lastVisitedRoute', location.pathname);
        }
    }, [location, isLoggedIn]);

    return null;
} export default RouteTracker;