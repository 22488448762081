import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    let initials = name.split('_').map(part => part[0].toUpperCase());
    if (initials.length === 1) {
        initials.push(name[1].toUpperCase());
    }
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 24,
            height: 24,
            fontSize: 14,
            marginRight: 1
        },
        children: initials.join(''),
    };
}

export default function BackgroundLetterAvatars({username}) {
    return (
        <Avatar {...stringAvatar(username)}/>
    );
}