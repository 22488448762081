import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@mui/system";
import { useTheme } from "./ThemeContext";

export default function PopupDialog({ open, handleClose }) {
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState("sm");

	const handleMaxWidthChange = (event) => {
		setMaxWidth(event.target.value);
	};

	const handleFullWidthChange = (event) => {
		setFullWidth(event.target.checked);
	};
	const { theme } = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				onClose={handleClose}>
				<DialogTitle align="center">
					DEMO MODE: Page under construction
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please use the desktop version. Mobile version is under work as
						well. Try these links to navigate to other sample pages.
					</DialogContentText>
					- Stocks Page:{" "}
					<a href="/s/NASDAQ:AAPL" style={{ color: theme.palette.text.green }}>
						stonkscentral.com/s/NASDAQ:AAPL
					</a>
					<br />- Communities Page:{" "}
					<a
						href="/community/WallStreetBets"
						style={{ color: theme.palette.text.green }}>
						stonkscentral.com/community/WallStreetBets
					</a>
					<br />- Post Page:{" "}
					<a
						href="/community/WallStreetBets/1"
						style={{ color: theme.palette.text.green }}>
						stonkscentral.com/community/WallStreetBets/1
					</a>
					<br />- Profile Page:{" "}
					<a
						href="/profile/ShortSqueeze"
						style={{ color: theme.palette.text.green }}>
						stonkscentral.com/profile/ShortSqueeze
					</a>
					<br />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}
