import { formatDistanceToNow, parseISO } from "date-fns";

class Utils {
	static getFormattedTime(createdAt) {
		if (!createdAt || typeof createdAt !== "string") {
			return "Invalid date";
		}
		try {
			const parsedDate = parseISO(createdAt);
			return formatDistanceToNow(parsedDate, {
				addSuffix: true,
			});
		} catch (error) {
			console.error("Error parsing date:", error);
			return "Invalid date";
		}
	}

	static abbreviateNumber(number) {
		if (typeof number !== "number") {
			return "Invalid number";
		}

		const abbreviations = [
			{ value: 1e9, symbol: "B" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e3, symbol: "k" },
		];

		for (let i = 0; i < abbreviations.length; i++) {
			if (number >= abbreviations[i].value) {
				return (
					(number / abbreviations[i].value).toFixed(1) + abbreviations[i].symbol
				);
			}
		}

		return number.toString();
	}

	// Add other utility methods here
}

export default Utils;
