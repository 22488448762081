// Inside your ChartCard component
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function LineChartComponent({data}) {
    return(<LineChart width={300} height={100} data={data}>
        <Line type="monotone" dataKey="uv" stroke="#8884d8" strokeWidth={2} />
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Tooltip />
        <Legend />
      </LineChart>)
}

export default LineChartComponent
// Inside the render method of ChartCard

