// StockInfo.js
import React from "react";
import {
	SymbolInfo,
	FundamentalData,
	CompanyProfile,
} from "react-tradingview-embed";

const StockInfo = React.memo(({ stockName }) => {
	return (
		<>
			<SymbolInfo
				widgetProps={{
					symbol: stockName,
					width: 400,
					height: 550,
					locale: "en",
				}}
			/>
			<div style={{ marginTop: "20px" }}>
				<FundamentalData
					widgetProps={{
						isTransparent: false,
						largeChartUrl: "",
						displayMode: "regular",
						width: 400,
						height: 550,
						colorTheme: "dark",
						symbol: stockName,
						locale: "en",
					}}
				/>
			</div>
			<div style={{ marginTop: "20px" }}>
				<CompanyProfile
					widgetProps={{
						width: 400,
						height: 550,
						isTransparent: false,
						colorTheme: "dark",
						symbol: stockName,
						locale: "en",
					}}
				/>
			</div>
		</>
	);
});

export default StockInfo;
