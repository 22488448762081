import React, { useState, useContext } from "react";
import "./Comment.css";
import { FaArrowDown, FaArrowUp, FaComment, FaShare } from "react-icons/fa"; // Assuming you have a corresponding CSS file for styles
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import BackgroundLetterAvatars from "./BackgroundLetterAvatar";
import Utils from "../utils/Utils";
import ReplyDialog from "./ReplyDialog";
import { AuthContext } from "../context/AuthContext";
import useCommentVote from "../hooks/useCommentVote";

const Comment = ({ comment, depth = 0, onReplyClick, updateComments }) => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const hasChildren = comment.childComments && comment.childComments.length > 0;
	const { handleVote, upvotes, downvotes } = useCommentVote(
		comment,
		updateComments
	);

	const handleReply = () => {
		onReplyClick(comment);
	};

	const ellapsedTime = Utils.getFormattedTime(comment.createdAt);
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	return (
		<div
			className={`comment ${isCollapsed ? "collapsed" : ""}`}
			style={{ marginLeft: `${depth * 3}px` }}>
			<div className="comment-header">
				<BackgroundLetterAvatars username={comment.userName} />
				<span className="username">{comment.userName}</span> •
				<span className="timestamp">{ellapsedTime}</span>
			</div>
			<div className="comment-body" style={{ whiteSpace: "pre-wrap" }}>
				<p>{comment.content}</p>
			</div>
			<div className="comment-footer">
				<button className="reply-btn" onClick={toggleCollapse}>
					{isCollapsed ? (
						<FaCirclePlus size={18} />
					) : (
						<FaCircleMinus size={18} />
					)}
				</button>
				<FaArrowUp
					className="upvotes-arrow"
					onClick={() => handleVote(comment, "UPVOTE")}
					size={14}
				/>
				<span className="upvotes" onClick={() => handleVote(comment, "UPVOTE")}>
					{upvotes}
				</span>
				<FaArrowDown
					className="downvotes-arrow"
					onClick={() => handleVote(comment, "DOWNVOTE")}
					size={14}
				/>
				<span
					className="downvotes"
					onClick={() => handleVote(comment, "DOWNVOTE")}>
					{downvotes}
				</span>
				<FaComment className="interaction-icon" onClick={handleReply} />
				<span className="interaction-icon" onClick={handleReply}>
					Reply
				</span>
				<FaShare className="share" />
			</div>
			{!isCollapsed && hasChildren && (
				<div className="nested-comments">
					{comment.childComments.map((child) => (
						<Comment
							key={child.commentId}
							comment={child}
							depth={depth + 1}
							onReplyClick={onReplyClick}
							updateComments={updateComments}
						/>
					))}
				</div>
			)}
		</div>
	);
};

const CommentSection = ({ comments, updateComments, onReplyClick, post }) => {
	const { isLoggedIn, user } = useContext(AuthContext);
	const [isReplying, setIsReplying] = useState(false);
	const [replyComment, setReplyComment] = useState("");
	const [parentComment, setParentComment] = useState(null);

	const handleReply = (comment) => {
		if (isLoggedIn) {
			setParentComment(comment);
			setIsReplying(true);
		} else {
			alert("You must be logged in to reply.");
		}
	};

	const handleReplySubmit = async () => {
		const commentData = {
			userId: user.id,
			postId: parentComment.postId,
			content: replyComment,
			parentCommentId: parentComment.commentId,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/comments`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(commentData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				const newComment = {
					commentId: responseData.commentId,
					userName: user.username,
					content: replyComment,
					upvotes: 0,
					downvotes: 0,
					createAt: responseData.createAt,
				};

				const updatedComments = comments.map((comment) => {
					if (comment.commentId === parentComment.commentId) {
						return {
							...comment,
							childComments: comment.childComments
								? [...comment.childComments, newComment]
								: [newComment],
						};
					}
					return comment;
				});

				updateComments(updatedComments);
				setIsReplying(false);
				setReplyComment("");
			} else {
				console.error("Failed to post reply");
			}
		} catch (error) {
			console.error("Error posting reply:", error);
		}
	};

	const handleReplyCancel = () => {
		setIsReplying(false);
		setReplyComment("");
	};

	return (
		<div>
			{comments &&
				comments.map((comment) => (
					<Comment
						key={comment.commentId}
						comment={comment}
						depth={0}
						updateComments={updateComments}
						onReplyClick={onReplyClick}
					/>
				))}
			<ReplyDialog
				open={isReplying}
				handleClose={handleReplyCancel}
				updateComments={updateComments}
				post={post}
				parentComment={parentComment}
				comments={comments}
			/>
		</div>
	);
};

export default CommentSection;
