import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@mui/material";
import LeftSidebarPosts from "../components/LeftSidebarPosts";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import {
	AdvancedChart,
	FundamentalData,
	SymbolInfo,
	CompanyProfile,
} from "react-tradingview-embed";
import { useTheme } from "../components/ThemeContext";
import CommentSection from "../components/CommentSection";
import PostInPostPage from "../components/PostInPostPage";
import { styled } from "@mui/material/styles";
import { useParams, useLocation } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BottomNavBarRouter from "../components/BottomNavBarRouter";
import { AuthContext } from "../context/AuthContext";
import ReplyDialog from "../components/ReplyDialog";
import StockInfo from "../components/StockInfo";

const StyledBox = styled(Box)(({ theme }) => ({
	root: {
		width: "100%",
		position: "fixed",
		bottom: 0,
		backgroundColor: "#080D1B",
		border: 4,
		borderColor: "#202F40",
		color: "#080D1B",
	},
	actionItem: {
		"& .MuiBottomNavigationAction-root": {
			color: "#61F977",
		},
		"& .MuiBottomNavigationAction-label": {
			color: "#FCFAD8",
		},
		"& .Mui-selected": {
			color: "#61F977",
		},
	},
}));

function PostPage() {
	const [width, setWidth] = useState(window.innerWidth);
	const { postId } = useParams();
	const location = useLocation();
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const isMobile = width <= 770;
	const [value, setValue] = React.useState("1");
	const { theme } = useTheme();
	const [post, setPost] = useState(location.state?.post || null);
	const [comments, setComments] = useState(null);
	const { isLoggedIn, user } = useContext(AuthContext);
	const [isReplying, setIsReplying] = useState(false); // Define isReplying
	const [parentComment, setParentComment] = useState(null); // Define parentComment

	const updateComments = (newComments) => {
		setComments(newComments);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleReplyCancel = () => {
		setIsReplying(false);
		setParentComment(null);
	};

	const handleReplyClick = (comment) => {
		if (!isLoggedIn) {
			alert("You must be logged in to comment.");
			return;
		}
		if (!post) {
			alert("Post is not yet loaded. Please wait.");
			return;
		}
		setParentComment(comment);
		setIsReplying(true);
	};

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		setPost(null); // Reset post to trigger loading state
		const fetchPost = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/byId?postId=${postId}`
				);
				const data = await response.json();
				setPost(data);
			} catch (error) {
				console.error("Error fetching post:", error);
			}
		};
		fetchPost();
	}, [postId]);

	useEffect(() => {
		if (!post) {
			const fetchPost = async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/byId?postId=${postId}`
					);
					const data = await response.json();
					setPost(data);
				} catch (error) {
					console.error("Error fetching post:", error);
				}
			};
			fetchPost();
		}
	}, [postId]); // Only depend on postId

	useEffect(() => {
		if (!comments) {
			const fetchComments = async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/comments/post/${postId}/nested`
					);
					const commentsData = await response.json();
					setComments(commentsData);
				} catch (error) {
					console.error("Error fetching comments:", error);
				}
			};
			fetchComments();
		}
	}, [postId]); // Only depend on postId

	if (!post) {
		return <div>Loading...</div>;
	}

	const tags = post.tags;
	const stockName = (() => {
		if (post.communityName.includes(":")) {
			return post.communityName;
		} else if (tags.find((tag) => tag.includes(":"))) {
			return tags.find((tag) => tag.includes(":"));
		} else {
			return null;
		}
	})();

	function postElement() {
		return (
			<div>
				<PostInPostPage
					key={postId}
					post={post}
					updateComments={updateComments}
					onReplyClick={handleReplyClick} // Pass handleReplyClick to PostInPostPage
				/>
				<div>
					<CommentSection
						comments={comments}
						onReplyClick={handleReplyClick} // Pass handleReplyClick to CommentSection
						updateComments={updateComments}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<ResponsiveTopBar isMobile={isMobile} />
			{isMobile ? (
				<Box sx={{ paddingBottom: "4rem" }}>
					<Box sx={{ width: "100%", typography: "body1" }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "divider",
									marginLeft: "1rem",
								}}>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example">
									<Tab
										label="Post"
										value="1"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
									<Tab
										label="Trending here"
										value="2"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
									<Tab
										label="Stock Info"
										value="3"
										sx={{
											fontSize: "0.75rem",
											padding: "6px 12px",
											minWidth: 50,
											color: theme.palette.secondary.main,
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
										}}
									/>
								</TabList>
							</Box>
							<TabPanel value="1"> {postElement()}</TabPanel>
							<TabPanel value="2">
								{" "}
								<LeftSidebarPosts />
							</TabPanel>
							<TabPanel value="3">
								{stockName && <StockInfo stockName={stockName} />}
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
			) : (
				<Grid
					container
					spacing={1}
					columns={10}
					justifyContent="space-evenly"
					alignItems="flex-start">
					<Grid item xs={2}>
						<LeftSidebarPosts />
					</Grid>
					<Grid item xs={4}>
						{postElement()}
					</Grid>
					{stockName ? (
						<Grid item xs={3}>
							<StockInfo stockName={stockName} />
						</Grid>
					) : (
						<Grid item xs={3}></Grid>
					)}
				</Grid>
			)}
			{isMobile && <BottomNavBarRouter bottomNavValue={bottomNavValue} />}
			{post ? (
				<ReplyDialog
					open={isReplying}
					handleClose={handleReplyCancel}
					updateComments={updateComments}
					post={post}
					parentComment={parentComment}
					comments={comments} // Pass comments as a prop
				/>
			) : null}
		</>
	);
}

export default PostPage;
