// PostMain.js
import React from "react";
import { FaArrowUp, FaArrowDown, FaShare, FaComment } from "react-icons/fa";
import "../css/RedditPost.css"; // Make sure to create a corresponding CSS file
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "./ThemeContext";
import TagsList from "./TagsList";
import { ThemeProvider } from "@mui/system";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import "../css/Styles.css";
import Utils from "../utils/Utils";

const PostCommunity = ({ post }) => {
	const { theme } = useTheme();
	const navigate = useNavigate();

	const getTagColor = (tag) => {
		if (tag.startsWith("c/")) {
			return theme.palette.tags.community;
		} else if (tag.startsWith("Crypto:")) {
			return theme.palette.tags.crypto;
		} else if (tag.startsWith("index/")) {
			return theme.palette.tags.index;
		} else {
			return theme.palette.tags.stock;
		}
	};

	const formattedTime = Utils.getFormattedTime(post.createdAt);

	return (
		<ThemeProvider theme={theme}>
			<div className="reddit-post">
				<div className="post-info">
					{/* <div className="community">{post.communityName}</div> */}
					<span className="time-user">{formattedTime} by &nbsp;</span>
					<span
						className="username"
						onClick={() => {
							navigate(`/profile/${post.userName}/${post.postId}`);
						}}>
						{post.userName}
					</span>
					<span>
						<TagsList tags={post.tags} marginTop={0} />
					</span>
					<h2
						className="post-title"
						onClick={() => {
							navigate(`/community/${post.communityName}/${post.postId}`, {
								state: { post },
							});
						}}>
						{post.title}
					</h2>
				</div>
				{post.images.length > 0 && (
					<Carousel swipe={true} height="18rem" autoPlay={false}>
						{post.images.map((image, index) => (
							<Card key={index} sx={{ backgroundColor: "#293b4e" }}>
								<CardMedia
									component="img"
									sx={{ height: "18rem", objectFit: "contain" }}
									image={`${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${image.url}`}
									alt={`image-${index}`}
								/>
							</Card>
						))}
					</Carousel>
				)}
				{/*<div className="image-section">*/}
				{/*    {post.images.map((image, index) => (*/}
				{/*        <img key={index} src={require("" + image)} alt={`Post content ${index}`}/>))}*/}
				{/*</div>*/}
				<div className="post-info">
					<p className="content" style={{ fontSize: "1rem" }}>
						<div
							className="clamped-content-3-lines"
							style={{ whiteSpace: "pre-wrap" }}>
							{post.content}
						</div>
					</p>
				</div>

				<div className="interaction-section">
					<FaArrowUp className="upvotes-arrow" />
					<span className="upvotes">
						{Utils.abbreviateNumber(post.upvotes)}
					</span>
					<FaArrowDown className="downvotes-arrow" />
					<span className="downvotes">
						{Utils.abbreviateNumber(post.downvotes)}
					</span>
					<FaComment className="interaction-icon" />
					<span className="comments">
						{Utils.abbreviateNumber(post.commentCount)}
					</span>
					<FaShare className="share" />
				</div>
			</div>
		</ThemeProvider>
	);
};

export default PostCommunity;
