import React from "react";
import "./App.css";
import MainContent from "./pages/MainContent";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CommunityPage from "./pages/CommunityPage";
import PostPage from "./pages/PostPage";
import ProfilePage from "./pages/ProfilePage";
import StocksPage from "./pages/StocksPage";
import SignInSignUpPage from "./pages/SignInSignUpPage";
import CreatePost from "./pages/CreatePost";

import { AuthProvider } from "./context/AuthContext";
import RouteTracker from "./context/RouteTracker";
import CreateCommunity from "./pages/CreateCommunity";
import { PageProvider } from "./context/PageContext";
import ErrorBoundary from "./utils/ErrorBoundary";
import {
	ThemeProvider as CustomThemeProvider,
	useTheme,
} from "./components/ThemeContext";

function App() {
	const { theme } = useTheme();
	return (
		<CustomThemeProvider>
			<ThemeProvider theme={theme}>
				<AuthProvider>
					<PageProvider>
						<Router>
							<RouteTracker />
							<div className="App">
								<Routes>
									<Route path="" element={<MainContent />} />
									<Route path="/" element={<MainContent />} />
									<Route path="/c/:communityName" element={<CommunityPage />} />
									<Route
										path="/community/:communityName"
										element={<CommunityPage />}
									/>
									<Route
										path="/community/:communityName/:postId"
										element={<PostPage />}
									/>
									<Route path="/s/:stockTicker" element={<StocksPage />} />
									<Route path="/post/:postId" element={<PostPage />} />
									<Route path="/profile/:username" element={<ProfilePage />} />
									<Route path="/u/:" element={<ProfilePage />} />
									<Route path="/signin" element={<SignInSignUpPage />} />
									<Route path="/signup" element={<SignInSignUpPage />} />
									<Route path="/createPost" element={<CreatePost />} />
									<Route
										path="/createCommunity"
										element={<CreateCommunity />}
									/>
								</Routes>
							</div>
						</Router>
					</PageProvider>
				</AuthProvider>
			</ThemeProvider>
		</CustomThemeProvider>
	);
}

export default App;
