import React, { useEffect, useRef, useState, memo } from "react";

function StockHeatMap() {
	const container = useRef();
	const [width, setWidth] = useState(window.innerWidth);

	const getWidgetDimensions = () => {
		return {
			width: width < 600 ? width.toString() : "100%",
			height: 450,
		};
	};

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		console.log("StockHeatMap rendered");

		const script = document.createElement("script");
		script.src =
			"https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js";
		script.type = "text/javascript";
		script.async = true;
		const { width, height } = getWidgetDimensions();
		const url =
			process.env.REACT_APP_SC_BASE_URL + "/s/{tvexchange}%3A{tvsymbol}";
		script.innerHTML = `
         {
          "exchanges": [],
          "dataSource": "SPX500",
          "grouping": "sector",
          "blockSize": "market_cap_basic",
          "blockColor": "change",
          "largeChartUrl": "${url}",
          "locale": "en",
          "symbolUrl":  "${url}",
          "colorTheme": "dark",
          "hasTopBar": false,
          "isDataSetEnabled": false,
          "isZoomEnabled": true,
          "hasSymbolTooltip": false,
          "isMonoSize": false,
          "width": "100%",
          "height": ${height}
        }`;

		const appendScript = () => {
			if (container.current) {
				container.current.appendChild(script);
			}
		};

		if (document.readyState === "complete") {
			appendScript();
		} else {
			window.addEventListener("load", appendScript);
		}

		return () => {
			if (container.current) {
				container.current.innerHTML = "";
			}
			window.removeEventListener("load", appendScript);
		};
	}, [width]);

	return (
		<div
			className="tradingview-widget-container"
			ref={container}
			style={{ zIndex: 1 }} // Ensure proper z-index
		></div>
	);
}

export default memo(StockHeatMap);
