// ReplyDialog.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../context/AuthContext";
import { styled } from "@mui/system";

// Styled components
const StyledGreenButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.text.dark,
	textTransform: "none",
	"&:hover": {
		backgroundColor: theme.palette.background.darkGreen,
		color: theme.palette.text.primary,
	},
}));

const StyledRedButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.dark,
	backgroundColor: theme.palette.background.cancel,
	borderColor: theme.palette.background.cancel,
	textTransform: "none",
	"&:hover": {
		backgroundColor: theme.palette.background.cancelHighlight,
		borderColor: theme.palette.background.cancelHighlight,
		color: theme.palette.text.primary,
	},
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	marginBottom: "0rem",
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&:hover fieldset": {
			borderColor: theme.palette.primary.main,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.primary.main,
		},
	},
	"& .MuiInputBase-input": {
		color: theme.palette.secondary.main,
		backgroundColor: `${theme.palette.background.default} !important`,
	},
	"& input:-webkit-autofill": {
		WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset !important`,
		WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
	},
}));

const ReplyDialog = ({
	open,
	handleClose,
	updateComments,
	post,
	parentComment,
	comments,
}) => {
	const theme = useTheme();
	const [width, setWidth] = useState(window.innerWidth);
	const isMobile = width <= 770;
	const fullScreen = isMobile;
	const [comment, setComment] = useState("");
	const { isLoggedIn, user } = useContext(AuthContext);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handleCommentSubmit = useCallback(async () => {
		if (!isLoggedIn) {
			alert("You must be logged in to comment.");
			return;
		}

		const commentData = {
			userId: user.id,
			postId: post.postId,
			content: comment,
			parentCommentId: parentComment ? parentComment.commentId : 0,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/comments`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(commentData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				const newComment = {
					commentId: responseData.commentId,
					userName: user.username,
					content: comment,
					upvotes: 0,
					downvotes: 0,
					createdAt: responseData.createdAt,
				};

				const addNestedComment = (comments, parentCommentId, newComment) => {
					return comments.map((comment) => {
						if (comment.commentId === parentCommentId) {
							return {
								...comment,
								childComments: comment.childComments
									? [...comment.childComments, newComment]
									: [newComment],
							};
						} else if (comment.childComments) {
							return {
								...comment,
								childComments: addNestedComment(
									comment.childComments,
									parentCommentId,
									newComment
								),
							};
						}
						return comment;
					});
				};

				let updatedComments;
				if (parentComment) {
					updatedComments = addNestedComment(
						comments,
						parentComment.commentId,
						newComment
					);
				} else {
					updatedComments = [...comments, newComment];
				}

				updateComments(updatedComments);
				setComment("");
				handleClose();
			} else {
				console.error("Failed to post comment");
			}
		} catch (error) {
			console.error("Error posting comment:", error);
		}
	}, [
		post,
		isLoggedIn,
		user,
		comment,
		parentComment,
		comments,
		updateComments,
		handleClose,
	]);

	if (!post) {
		return null;
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth="md"
			fullWidth
			PaperProps={{
				elevation: 0,
				sx: {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					boxShadow: "none",
					borderRadius: "1rem",
					border: `1px solid ${theme.palette.background.gray}`,
				},
			}}>
			<DialogTitle sx={{ color: theme.palette.text.primary }}>
				Reply to Comment
			</DialogTitle>
			<DialogContent sx={{ color: theme.palette.text.primary }}>
				{parentComment && (
					<div style={{ marginBottom: "1rem", fontStyle: "italic" }}>
						{parentComment.content}
					</div>
				)}
				<StyledTextField
					autoFocus
					margin="dense"
					label="Add Comment..."
					type="text"
					fullWidth
					multiline
					rows={4}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
			</DialogContent>
			<DialogActions
				sx={{ color: theme.palette.text.primary, margin: "0.5rem" }}>
				<StyledRedButton onClick={handleClose} sx={{ marginRight: "1rem" }}>
					Cancel
				</StyledRedButton>
				<StyledGreenButton
					onClick={handleCommentSubmit}
					sx={{ marginRight: "1rem" }}>
					Submit
				</StyledGreenButton>
			</DialogActions>
		</Dialog>
	);
};

export default ReplyDialog;
