import React from "react";
import { Card, CardContent, Typography, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "./ThemeContext";
import { ThemeProvider } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Box, Rating } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import AnalysisTable from "./AnalysisTable";
import CardMedia from "@mui/material/CardMedia";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ProfileComponent = ({ username }) => {
	// Mock data for stock coverage
	const { theme } = useTheme();

	const styles = {
		card: {
			marginBottom: "2rem",
			backgroundColor: theme.palette.background.gray,
			textColor: theme.palette.text.primary,
		},
		text: {
			color: theme.palette.text.primary,
			// fontSize: '1.5rem',
		},
		table: {
			// margin: '0.5rem',
			marginBottom: "1rem",
			backgroundColor: theme.palette.background.paper,
			textColor: theme.palette.text.primary,
		},
		tableHeading: {
			color: theme.palette.text.green,
			fontSize: "1.1rem",
		},
		chip: {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.text.dark,
			textColor: theme.palette.text.dark,
		},
		multiChip: {
			height: "auto",
			"& .MuiChip-label": {
				display: "block",
				whiteSpace: "normal",
			},
			backgroundColor: theme.palette.success.main,
			color: theme.palette.text.dark,
		},
		divider: {
			borderColor: theme.palette.success.main,
		},
	};
	// Mock data for user details
	const userDetails = {
		postKarma: 5059,
		commentKarma: 1931,
		followers: 15,
		cakeDay: "Apr 21, 2020",
		goldReceived: 0,
	};
	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === "dark"
				? theme.palette.background.paper
				: theme.palette.background.paper,
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		borderColor: theme.palette.primary.main,
		variant: "outlined",
	}));

	// Function to render user details card
	const renderUserDetailsCard = () => {
		return (
			<Card style={{ marginBottom: "2rem", backgroundColor: "inherit" }}>
				<CardContent>
					<Grid
						container
						direction="row"
						justifyContent="space-around"
						alignItems="stretch"
						spacing={2}>
						<Grid item xs={4}>
							<Item elevation={3} variant="outlined" theme={theme}>
								Post Tendies <br /> {userDetails.postKarma}
							</Item>
						</Grid>
						<Grid item xs={4}>
							<Item elevation={3} variant="outlined" theme={theme}>
								Comment Tendies <br /> {userDetails.commentKarma}
							</Item>
						</Grid>
						<Grid item xs={4}>
							<Item elevation={3} variant="outlined" theme={theme}>
								Followers <br /> {userDetails.followers}
							</Item>
						</Grid>
						<Grid item xs={4}>
							<Item elevation={3} variant="outlined" theme={theme}>
								Account Created <br /> {userDetails.cakeDay}
							</Item>
						</Grid>
						<Grid item xs={4}>
							<Item elevation={3} variant="outlined" theme={theme}>
								Awards Received <br /> {userDetails.goldReceived}
							</Item>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					backgroundColor: theme.palette.background.default,
					minHeight: "100vh",
					padding: "2rem",
				}}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Box
							sx={{
								bgcolor: "background.paper",
								boxShadow: 1,
								borderRadius: 1,
								p: 2,
								minWidth: 300,
							}}>
							<CardMedia
								sx={{ height: 160 }}
								image={require("./posts_images/profile_banner.webp")}
								title="banner"
							/>

							<Grid
								container
								direction="column"
								alignItems="center"
								sx={{ marginTop: "-5rem" }}>
								<Avatar
									alt="UserName"
									src={require("./posts_images/avatar.webp")}
									sx={{ width: "10rem", height: "10rem" }}
								/>
								<Grid item></Grid>
								<Grid item>
									<Box
										sx={{
											color: "text.secondary",
											fontSize: 24,
											fontWeight: "medium",
											marginTop: "1rem",
										}}>
										{username}
									</Box>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justifyContent="space-around"
								alignItems="center"
								spacing={1}
								sx={{ marginTop: "1rem" }}>
								<Grid item xs={3}>
									<Box
										sx={{
											color: "text.green",
											display: "inline",
											fontWeight: "medium",
											mx: 0.5,
										}}>
										1640
									</Box>
									<Typography variant="subtitle2" component="div">
										Post Tendies
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Box
										sx={{
											color: "text.green",
											display: "inline",
											fontWeight: "medium",
											mx: 0.5,
										}}>
										1238
									</Box>
									<Typography variant="subtitle2" component="div">
										Comment Tendies
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Box
										component={TrendingUpIcon}
										sx={{
											color: "text.green",
											fontSize: 16,
											verticalAlign: "sub",
										}}
									/>
									<Box
										sx={{
											color: "text.green",
											display: "inline",
											fontWeight: "medium",
											mx: 0.5,
										}}>
										1428
									</Box>
									<Typography variant="subtitle2" component="div">
										Followers
									</Typography>
								</Grid>
							</Grid>

							<Divider
								color="secondary"
								style={styles.divide}
								sx={{ margin: "1rem", color: "primary" }}>
								{/*<Chip label="User Details" size="small" style={styles.chip}/>*/}
							</Divider>
							<div>
								<Accordion>
									<AccordionSummary
										expandIcon={
											<ArrowDownwardIcon
												style={{ color: theme.palette.text.green }}
											/>
										}
										aria-controls="panel1-content"
										id="panel1-header">
										<Typography style={{ color: theme.palette.text.primary }}>
											About Me
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Diving deep into the high-stakes world of investing, I'm
											ShortSqueezer, your go-to enthusiast for daring market
											maneuvers and aggressive trades. I thrive on volatility
											and the thrill of the trade, especially when it comes to
											short squeezing overvalued stocks.
										</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={
											<ArrowDownwardIcon
												style={{ color: theme.palette.text.green }}
											/>
										}
										aria-controls="panel1-content"
										id="panel1-header">
										<Typography style={{ color: theme.palette.text.primary }}>
											Investment Philosophy
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											I believe in the power of leverage and precision timing to
											capitalize on market inefficiencies. While my strategies
											might not suit the faint-hearted, they're crafted for
											those who seek substantial rewards from substantial risks.
										</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={
											<ArrowDownwardIcon
												style={{ color: theme.palette.text.green }}
											/>
										}
										aria-controls="panel1-content"
										id="panel1-header">
										<Typography style={{ color: theme.palette.text.primary }}>
											Investment Strategies
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											- Short Squeezes: I specialize in identifying stocks that
											are ripe for a short squeeze, analyzing market sentiments,
											trading volumes, and short interest.
											<br />
											<br />
											- High-Risk Trades: From options to futures, I engage in
											trades that can turn the market's unpredictability into a
											profitable venture.
											<br />
											<br />
											- Market Analysis: I share real-time insights and detailed
											analyses on market trends that cater to fellow
											risk-takers.
											<br />
											<br />
										</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={
											<ArrowDownwardIcon
												style={{ color: theme.palette.text.green }}
											/>
										}
										aria-controls="panel1-content"
										id="panel1-header">
										<Typography style={{ color: theme.palette.text.primary }}>
											Disclaimer
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											The strategies and investments discussed here carry a high
											level of risk and may not be suitable for all investors.
											Always conduct your own research or consult with a
											financial advisor.
										</Typography>
									</AccordionDetails>
								</Accordion>
							</div>
						</Box>
					</Grid>
					<Grid item xs={8}>
						<Box
							sx={{
								bgcolor: "background.paper",
								boxShadow: 1,
								borderRadius: 1,
								p: 2,
								minWidth: 300,
							}}>
							<Box
								sx={{
									color: "text.secondary",
									fontSize: 24,
									fontWeight: "medium",
									margin: "1rem",
									marginLeft: "1.5rem",
									marginBottom: "1rem",
								}}>
								Post History
							</Box>
							{/*<TableMUI/>*/}
							<AnalysisTable />
						</Box>
					</Grid>
				</Grid>
			</div>
		</ThemeProvider>
	);
};

export default ProfileComponent;
