export const miniChartData =[ {
  "ticker": "DJI",
  "name": "Dow Jones",
  "price": 27386,
  "change_sign": "-",
  "change_amount": "3.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
},
{
  "ticker": "SPY",
  "name": "S&P 500",
  "price": 333.48,
  "change_sign": "+",
  "change_amount": "0.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
},
{
  "ticker": "AAPL",
  "name": "Apple",
  "price": 110.34,
  "change_sign": "+",
  "change_amount": "1.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
},
{
  "ticker": "TSLA",
  "name": "Tesla",
  "price": 420.34,
  "change_sign": "+",
  "change_amount": "2.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
},
{
  "ticker": "GOOGL",
  "name": "Google",
  "price": 1500.34,
  "change_sign": "+",
  "change_amount": "0.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
},
{
  "ticker": "AMZN",
  "name": "Amazon",
  "price": 3200.34,
  "change_sign": "+",
  "change_amount": "1.5%",
  "chart": [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]
}]
